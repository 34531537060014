
import { Navigate, Route, Routes } from "react-router-dom";
import { EducaSignLayout } from "../../shared/layouts/EducaSignLayout";
import { StatisticsPage } from "../pages/StatisticsPage";
import { ROUTES } from "../../router/routes";

export const StatisticsRoutes = () => {
    return (
        <>
            <Route path={ROUTES.ESTADISTICAS} element={<StatisticsPage />} />
        </>
    )
}
