import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthRoutes } from "../auth/routes/AuthRoutes";
import { DevelopersRoutes } from "../developers/routes/DevelopersRoutes";
import { TopNavUnauthenticatedLayout } from "../shared/layouts/TopNavUnauthenticatedLayout";
import { ValidationPage } from "../validation/pages/ValidationPage";
import { ROUTES } from "./routes";
export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {DevelopersRoutes()}
        {AuthRoutes()}
        <Route
          element={
            <TopNavUnauthenticatedLayout
              backButtonVisible={false}
              loginButtonVisible={false}
            />
          }
        >
          <Route
            path={`${ROUTES.VALIDACION}/:signProcessId`}
            element={<ValidationPage />}
          />
        </Route>
      </Routes>
    </Router>
  );
};
