import React from 'react'
import { NavOrganizationSetting } from '../components/NavOrganizationSetting'
import { Outlet } from 'react-router-dom'

export const NavOrganizationSettingLayout = () => {
    return (
        <div className='flex flex-col h-full  px-4 py-2 '>
            <NavOrganizationSetting />
            <Outlet />
        </div>
    )
}
