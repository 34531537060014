import { Outlet } from "react-router-dom"
import { UnauthenticatedTopNav } from "../../auth/components/UnauthenticatedTopNav"


interface TopNavUnauthenticatedLayoutProps {
    backButtonVisible?: boolean;
    loginButtonVisible?: boolean;
}

export const TopNavUnauthenticatedLayout = ({ backButtonVisible = true, loginButtonVisible = true }: TopNavUnauthenticatedLayoutProps) => {
    return (
        <div className="flex flex-col h-lvh">
            <UnauthenticatedTopNav backButtonVisible={backButtonVisible} loginButtonVisible={loginButtonVisible} />
            <Outlet />
        </div>
    )
}
