import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../auth/context/AuthContext';
import { ROUTES } from '../../../router/routes';
import { useAuth } from '../../../auth/hooks/useAuth';
import { usePermissions } from '../../../auth/hooks/usePermission';
import { Roles } from '../../../auth/types/Roles';
import { Permissions } from '../../../auth/types/Permissions';


export const UserAvatar = () => {
  const { logout } = useAuth()
  const { nameUser } = useAuthContext()
  const firstLetterName = nameUser && typeof nameUser === 'string' ? nameUser.charAt(0).toUpperCase() : ' '
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const { hasRole, hasAccess } = usePermissions();

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className='flex justify-center items-center gap-3 size-full'
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <div className="bg-CorporatePrimary rounded-full border-4 border-green-100 aspect-square size-10 flex justify-center items-center">
          <p className="font-bold text-white">{firstLetterName}</p>
        </div>
        <div className='hidden md:block'>
          <p className="button16 text-NeutralNeutral2 text-ellipsis whitespace-nowrap max-w-24 overflow-hidden">
            {nameUser}
          </p>
        </div>
        <div className='hidden md:block'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-NeutralNeutral2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  z-50">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {
              hasRole(Roles.Developer) &&
              <Link to={ROUTES.DEVELOPERS} className="flex items-center justify-center w-full px-4 py-2 bodyl hover:bg-gray-100" role="menuitem">Developers</Link>
            }
            {
              hasAccess(Permissions.SettingsRead) &&
              <Link to={ROUTES.ORGANIZATIONS_SETTINGS} className="flex items-center justify-center w-full px-4 py-2 bodyl hover:bg-gray-100" role="menuitem">Ajustes</Link>
            }
            <Link to={ROUTES.FAQS} className="flex items-center justify-center w-full px-4 py-2 bodyl hover:bg-gray-100" role="menuitem">Ayuda</Link>
            <button
              className="flex items-center justify-center w-full px-4 py-2 bodyl text-red-500 hover:bg-gray-100 hover:text-red-700"
              role="menuitem"
              onClick={() => {
                logout()
              }}
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      )}
    </div>

  )
}
