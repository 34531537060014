import { createContext, useContext, useState } from "react"
import { Outlet } from "react-router-dom"
import { QRData, Size, TextValidationData, type FirmanteData } from "../types"
import { usePagination } from "../../shared/hooks/usePagination"

interface DocumentTemplateContextType {
    nameDocument: string
    setNameDocument: (name: string) => void
    numeroFirmantes: number
    setNumeroFirmantes: (num: number) => void
    fileTemplate: File[]
    setFileTemplate: (files: File[]) => void
    textoLateral: string
    setTextoLateral: (text: string) => void
    isAñadirTexto: boolean
    setIsAñadirTexto: (value: boolean) => void
    isUrlValidacion: boolean
    setIsUrlValidacion: (value: boolean) => void
    isQR: boolean
    setQR: (value: boolean) => void
    location: string
    setLocation: (location: string) => void
    orientationValidation: string
    setOrientationValidation: (orientation: string) => void
    firmantes: FirmanteData[]
    setFirmantes: (firmantes: FirmanteData[]) => void
    currentPage: number
    numPages: number
    QRData: QRData
    setQRData: (QR: QRData) => void
    TextValidationData: TextValidationData
    setTextValidationData: (TextValidation: TextValidationData) => void
    reason: string
    setReason: (reason: string) => void
    pdfViewerDimensions: Size | undefined
    setPdfViewerDimensions: (dimensions?: Size | undefined) => void
    pdfOriginalDimensions: Size
    setPdfOriginalDimensions: (dimensions: Size) => void,
    onDocumentLoadSuccess: (numPages: number) => void,
    onPageChange: (newPage: number) => void,
}

const DocumentTemplateContext = createContext<DocumentTemplateContextType | undefined>(undefined)

export const DocumentTemplateProvider = () => {
    const { currentPage, numPages, onDocumentLoadSuccess, onPageChange } = usePagination()

    const [nameDocument, setNameDocument] = useState("")
    const [numeroFirmantes, setNumeroFirmantes] = useState(0)
    const [fileTemplate, setFileTemplate] = useState<File[]>([])
    const [textoLateral, setTextoLateral] = useState("")
    const [isAñadirTexto, setIsAñadirTexto] = useState(false)
    const [isUrlValidacion, setIsUrlValidacion] = useState(false)
    const [isQR, setQR] = useState(false)
    const [location, setLocation] = useState("Izquierda")
    const [orientation, setOrientation] = useState<string>("Vertical")
    const [firmantes, setFirmantes] = useState<FirmanteData[]>([])
    const [QRData, setQRData] = useState<QRData>({ size: { width: 50, height: 50 }, coordinates: { x: 0, y: 0 } })
    const [TextValidationData, setTextValidationData] = useState<TextValidationData>({ size: { width: 400, height: 20 }, coordinates: { x: 0, y: 0 } })
    const [reason, setReason] = useState("")
    const [pdfViewerDimensions, setPdfViewerDimensions] = useState<Size>()
    const [pdfOriginalDimensions, setPdfOriginalDimensions] = useState<Size>({ width: 0, height: 0 })

    return (
        <DocumentTemplateContext.Provider
            value={{
                nameDocument,
                setNameDocument,
                numeroFirmantes,
                setNumeroFirmantes,
                fileTemplate,
                setFileTemplate,
                textoLateral,
                setTextoLateral,
                isAñadirTexto,
                setIsAñadirTexto,
                isUrlValidacion,
                setIsUrlValidacion,
                isQR,
                setQR,
                location,
                setLocation,
                orientationValidation: orientation,
                setOrientationValidation: setOrientation,
                firmantes,
                setFirmantes,
                currentPage,
                numPages,
                QRData,
                setQRData,
                TextValidationData,
                setTextValidationData,
                reason,
                setReason,
                pdfViewerDimensions,
                setPdfViewerDimensions,
                pdfOriginalDimensions,
                setPdfOriginalDimensions,
                onDocumentLoadSuccess,
                onPageChange,

            }}
        >
            <Outlet />
        </DocumentTemplateContext.Provider>
    )
}

export const useDocumentTemplateContext = () => {
    const context = useContext(DocumentTemplateContext)
    if (context === undefined) {
        throw new Error("useDocumentTemplate must be used within a DocumentTemplateProvider")
    }
    return context
}

