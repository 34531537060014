
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { usePermissions } from '../../auth/hooks/usePermission';
import { Permissions } from '../../auth/types/Permissions';
import api from '../../shared/api/Axios';
import { DocumentEndpoints } from '../../shared/api/services/ConfigApi';
import { Checkbox } from '../../shared/components/CheckBox';
import CheckCircle from '../../shared/Icons/CheckCircle';
import ErrorIcon from '../../shared/Icons/ErrorIcon';
import { EyeIcon } from '../../shared/Icons/EyeIcon';
import Fingerprint from '../../shared/Icons/Fingerprint';
import InfoIcon from '../../shared/Icons/InfoIcon';
import { fechaActualizacionDocumentos } from '../../shared/utils/dates/fechaActualizacionDocumentos';
import { FilterDocument, useDocumentosContext } from '../context/DocumentosContext';
import { DocumentStatusEnum } from '../types/DocumentsByUserRequest';

export interface ItemListaDocumentosProps {
    documento: FilterDocument;
}

export const ItemListaDocumentos = ({ documento }: ItemListaDocumentosProps) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { selectedDocuments, handleDocumentSelect } = useDocumentosContext()

    const isSelected = selectedDocuments.map(doc => doc.id).includes(documento.id)

    const { refetch, isFetching } = useQuery({
        queryFn: async () => {
            const response = await api.get(DocumentEndpoints.getFile(documento.id))
            return response.data;
        },
        queryKey: ["getFile", documento.id],
        enabled: false
    });

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const result = await refetch();
            if (result.data) {
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${result.data}`;
                link.download = `${documento.documentName}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No data received from refetch');
            }
        } catch (err) {
            console.error('Error downloading file:', err);
            alert('Error al descargar el archivo. Por favor, inténtelo de nuevo.');
        } finally {
            setIsDownloading(false);
        }
    };

    const { hasAccess } = usePermissions();

    return (
        <div className='flex justify-between w-full pl-2 pr-4 py-2 border mb-3 border-slate-200 rounded-lg'>
            <div className='flex items-center '>
                {
                    hasAccess(Permissions.DocumentsSign) &&
                    <Checkbox
                        checked={isSelected}
                        disabled={documento.type !== DocumentStatusEnum.Pending ? true : false}
                        onChange={() => handleDocumentSelect && handleDocumentSelect(documento)}
                    />
                }

                <div className='flex flex-col gap-1 px-4'>
                    <div className='flex items-center gap-4'>
                        <p className='button16 text-NeutralNeutral1'>{documento.documentName}</p>
                        <div className='hidden md:block rounded-full bg-slate-100 px-2 py-1'>
                            <p className='bodys text-NeutralNeutral2'>{documento.id}</p>
                        </div>

                    </div>
                    <div className='flex gap-1 items-center'>
                        {(() => {
                            switch (documento.type) {
                                case DocumentStatusEnum.Pending:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <Fingerprint size={14} />
                                            <span className="bodys text-NeutralNeutral2">Pendiente de firmar</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.InProgress:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <InfoIcon size={14} />
                                            <span className="bodys text-NeutralNeutral2">Pendiente de otros firmantes</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.Completed:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <CheckCircle size={14} />
                                            <span className="bodys text-NeutralNeutral2">Completado</span>
                                        </div>
                                    );
                                case DocumentStatusEnum.Canceled:
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <ErrorIcon size={14} />
                                            <span className="bodys text-NeutralNeutral2">Cancelado</span>
                                        </div>
                                    );
                                default: // Manejo de otros estados o errores
                                    return (
                                        <div className='flex items-center gap-2'>
                                            <ErrorIcon size={14} />
                                            <span className="bodys text-NeutralNeutral2">Mensajes de error</span>
                                        </div>
                                    );
                            }
                        })()}
                    </div>
                </div>
            </div>
            <div className='flex items-center gap-4'>
                <p className='hidden md:block bodys text-NeutralNeutral2'>{fechaActualizacionDocumentos(documento.updatedDate?.toString() ?? "")}</p>
                <button
                    className='relative styled-like-a-button'
                    onClick={handleDownload}
                    disabled={isDownloading || isFetching}
                    aria-label={isDownloading || isFetching ? "Descargando documento" : "Descargar documento"}
                >
                    {isDownloading || isFetching ? (
                        <EyeIcon color='#d6d6d6' />
                    ) : (
                        <EyeIcon />
                    )}
                </button>
            </div>
        </div>

    )
}
