import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { Coordinates, Size } from "../types"

export const useSignature = () => {
    const { firmantes, setFirmantes, numeroFirmantes, setNumeroFirmantes, reason, setReason, currentPage } = useDocumentTemplateContext()

    const handleSizeSignatureChange = (index: number, size: Size) => {
        const newFirmantes = firmantes.map((firmante, i) =>
        (i === index
            ? { ...firmante, size }
            : firmante))
        setFirmantes(newFirmantes)
    }

    const handleCoordinatesSignatureChange = (index: number, coordinates: Coordinates) => {
        const newFirmantes = firmantes.map((firmante, i) =>
            i === index ? { ...firmante, coordinates } : firmante
        );
        setFirmantes(newFirmantes);
    };


    const handleAddFirmante = () => {
        const newNumeroFirmantes = numeroFirmantes + 1
        setNumeroFirmantes(newNumeroFirmantes)
        setFirmantes([
            ...firmantes,
            {
                nombreFirma: "Firma " + newNumeroFirmantes,
                isAñadirLogo: false,
                fileLogo: [],
                page: currentPage,
                size: { width: 200, height: 70 },
                coordinates: { x: 150, y: 205 },
                reason: ""
            },
        ])
    }

    const handleSubstractFirmante = () => {
        const newNumeroFirmantes = numeroFirmantes - 1
        setNumeroFirmantes(newNumeroFirmantes)
        setFirmantes(firmantes.slice(0, -1))
    }

    const handleNombreChange = (index: number, value: string) => {
        const newFirmantes = firmantes.map((firmante, i) => (i === index ? { ...firmante, nombreFirma: value } : firmante))
        setFirmantes(newFirmantes)
    }

    const handleAñadirLogoChange = (index: number, value: boolean) => {
        const newFirmantes = firmantes.map((firmante, i) => (i === index ? { ...firmante, isAñadirLogo: value } : firmante))
        setFirmantes(newFirmantes)
    }

    const handleFileLogoChange = (index: number, files: File[]) => {
        const newFirmantes = firmantes.map((firmante, i) => (i === index ? { ...firmante, fileLogo: files } : firmante))
        setFirmantes(newFirmantes)
    }

    const handlePageSignatureChange = (index: number, page: number) => {
        const newFirmantes = firmantes.map((firmante, i) => (i === index ? { ...firmante, page } : firmante))
        setFirmantes(newFirmantes)
    }

    const handleReasonChange = (index: number, reason: string) => {
        const newFirmantes = firmantes.map((firmante, i) => (i === index ? { ...firmante, reason: reason } : firmante))
        setFirmantes(newFirmantes)
    }


    return { firmantes, handleSizeSignatureChange, handleCoordinatesSignatureChange, handleAddFirmante, handleSubstractFirmante, handleNombreChange, handleAñadirLogoChange, handleFileLogoChange, handlePageSignatureChange, numeroFirmantes, setNumeroFirmantes, reason, handleReasonChange }
}