import { Navigate, Route, Routes } from "react-router-dom";
import { usePermissions } from "../auth/hooks/usePermission";
import { ExternalLoginPage } from "../auth/pages/ExternalLoginPage";
import { DocumentsRoutes } from "../documents/routes/DocumentsRoutes";
import { StatisticsRoutes } from "../statistics/routes/StatisticsRoutes";
import { TemplatesRoutes } from "../Templates/routes/TemplatesRoutes";
import { ROUTES } from "./routes";
import { getInitialRoute } from "./utils/GetInitialRoute";
import { settingsRoute } from "../settings/routes/settingsRoute";
import { NavBarProvider } from "../shared/components/nav/context/NavBarContext";
import { EducaSignLayout } from "../shared/layouts/EducaSignLayout";
import { DocumentTemplateProvider } from "../Templates/context/DocumentTemplateContext";
import { CreateDocumentTemplateLayout } from "../Templates/layouts/CreateDocumentTemplateLayout";
import { CreateDocumentTemplatePage } from "../Templates/pages/CreateDocumentTemplatePage";
export const PrivateRoutes = () => {
  const { hasAccess, isLoaded: isPermissionsLoaded } = usePermissions();
  const initialRoute = getInitialRoute(hasAccess, isPermissionsLoaded);
  return (
    <Routes>
      <Route element={<NavBarProvider />}>
        <Route element={<DocumentTemplateProvider />}>
          <Route element={<CreateDocumentTemplateLayout />}>
            <Route
              path={`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/:nameTemplate`}
              element={<CreateDocumentTemplatePage />}
            />
          </Route>
        </Route>

        {settingsRoute()}

        <Route element={<EducaSignLayout />}>
          {DocumentsRoutes()}
          {StatisticsRoutes()}
          {TemplatesRoutes()}

          <Route path={ROUTES.EXTERNALLOGIN} element={<ExternalLoginPage />} />
          {isPermissionsLoaded && (
            <>
              <Route index element={<Navigate to={initialRoute} replace />} />

              <Route
                path="*"
                element={<Navigate to={initialRoute} replace />}
              />
            </>
          )}
        </Route>
      </Route>
    </Routes>
  );
};
