import { useState } from 'react'
import { globals } from '../../shared/constants/global'
import { FooterSignaturePreviewDocumentViewer } from '../components/SignaturePreview/FooterSignaturePreviewDocumentViewer'
import { SignaturePreviewDocumentViewer } from '../components/SignaturePreview/SignaturePreviewDocumentViewer'
import { ModalFirma } from '../components/ModalFirma'
import { useDocumentosContext } from '../context/DocumentosContext'
import { PendingDocument } from '../types/DocumentsByUserRequest'
import { fechaActualizacionDocumentos } from '../../shared/utils/dates/fechaActualizacionDocumentos'

export const SignaturePreviewPage = () => {

    const [currentDocumentFile, setCurrentDocumentFile] = useState<string>('')
    const [currentDocument, setCurrentDocument] = useState<PendingDocument>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { selectedDocuments, handleUnSelectAll } = useDocumentosContext()
    const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>(0)

    const handleModalClose = () => {
        setIsModalOpen(false); // Cerrar el modal
    };

    const handleSignDocuments = () => {
        handleUnSelectAll();
        setIsModalOpen(false); // Cerrar el modal después de firmar
    };
    return (
        <>

            <div className={` min-h-[calc(100vh-${globals.ALTURA_TOPNAV}px)] size-full overflow-hidden bg-NeutralNeutral6 p-4`}>
                <div className='flex flex-col size-full bg-white rounded-lg py-2 px-4'>
                    <div className='flex flex-col '>
                        <div className='flex justify-between items-center'>

                            <p className='button16 text-NeutralNeutral1'>{currentDocument?.documentName} </p>
                            <div className='hidden md:flex bg-white justify-center items-center bodymbold text-NeutralNeutral2'>
                                Documento: {currentDocumentIndex + 1} de {selectedDocuments.length}
                            </div>
                        </div>
                        <p className='bodys text-NeutralNeutral2'> {fechaActualizacionDocumentos(currentDocument?.updatedDate?.toString() ?? "")}</p>
                        <p></p>
                    </div>
                    <SignaturePreviewDocumentViewer
                        currentDocumentFile={currentDocumentFile} />
                    <div className='md:hidden bg-white flex justify-center items-center bodymbold text-NeutralNeutral2'>
                        {currentDocumentIndex + 1} de {selectedDocuments.length}
                    </div>
                    {/* <hr className='border-NeutralNeutral6 border-2' /> */}
                    <FooterSignaturePreviewDocumentViewer
                        setCurrentDocumentFile={setCurrentDocumentFile}
                        setModalOpen={setIsModalOpen}
                        currentDocumentIndex={currentDocumentIndex}
                        setCurrentDocumentIndex={setCurrentDocumentIndex}
                        setCurrentDocument={setCurrentDocument} />

                </div>
            </div>
            <ModalFirma
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onSign={handleSignDocuments}
                selectedDocuments={selectedDocuments}
            />
        </>
    )
}
