import { useOrganizationSettingsContext } from '../context/OrganizationSettingsContext'
import { AlianzasSettingsOrganization } from '../views/Alianzas/AlianzasSettingsOrganization'
import { GeneralSettingsOrganization } from '../views/General/GeneralSettingsOrganization'
import { GruposSettingsOrganization } from '../views/Grupos/GruposSettingsOrganization'
import { MiembrosSettingsOrganization } from '../views/Miembros/MiembrosSettingsOrganization'

export const OrganizationSettingPage = () => {
    const { viendo } = useOrganizationSettingsContext()

    return (
        <div className="flex flex-col flex-1 gap-4 h-full">
            {viendo === "general" && <GeneralSettingsOrganization />}
            {viendo === "grupos" && <GruposSettingsOrganization />}
            {viendo === "miembros" && <MiembrosSettingsOrganization />}
            {viendo === "alianzas" && <AlianzasSettingsOrganization />}
        </div>
    )
}
