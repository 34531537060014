import React, { useState } from 'react'
import Button, { ButtonSize } from '../../../shared/components/Button'
import { filters, useOrganizationSettingsContext } from '../context/OrganizationSettingsContext'

export const NavOrganizationSetting = () => {
    const { viendo, setViendo } = useOrganizationSettingsContext()

    const getButtonProps = (filter: typeof filters[number]) => ({
        onClick: () => setViendo(filter),
        size: ButtonSize.medium,

        text: `${filter === 'general' ? 'General' :
            filter === 'miembros' ? 'Miembros' :
                filter === 'grupos' ? 'Grupos ' :
                    'Alianzas'}`,
        hasBg: viendo === filter

    })

    return (
        <div className='flex'>

            <div className=' overflow-x-scroll py-2'
                style={{
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
            >
                <div className='flex flex-row gap-4 flex-nowrap w-full'>
                    <Button {...getButtonProps("general",)} />
                    <Button {...getButtonProps("miembros")} />
                    <Button {...getButtonProps("grupos")} />
                    <Button {...getButtonProps("alianzas")} />
                </div>
            </div>
        </div>
    )
}
