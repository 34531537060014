export const Permissions = {
  TemplatesRead: "templates:read",
  TemplatesEdit: "templates:edit",
  TemplatesCreate: "templates:create",
  TemplatesDelete: "templates:delete",
  DocumentsRead: "documents:read",
  DocumentsEdit: "documents:modificacion",
  DocumentsSign: "documents:sign",
  DocumentsOrganization: "documents:organization",
  StatisticsRead: "statistics:read",
  SettingsRead: "settings:read",
  SettingsOrganizationRead: "settingsOrganization:read",
  DocumentsViewCancelled: "documentsView:Cancelled",
} as const;

export type Permission = (typeof Permissions)[keyof typeof Permissions];
