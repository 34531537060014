import { Link, useLocation, useNavigate } from 'react-router-dom'
import { globals } from '../../shared/constants/global'
import ReturnIcon from '../../shared/Icons/ReturnIcon'
import { UserAvatar } from '../../shared/components/nav/UserAvatar'
import EducaSignLogo from '../../assets/icons/EducaSignLogo.svg'
import { ROUTES } from '../../router/routes'
import { useAuthContext } from '../../auth/context/AuthContext'
import { is } from 'date-fns/locale'

export const ConfigurationTopNav = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation();
    const { selectedOrg } = useAuthContext();


    const isSelected = [ROUTES.ORGANIZATIONS_SETTINGS, ROUTES.CERTIFICATIONS_SETTINGS, ROUTES.NOTIFICATIONS_SETTINGS, ROUTES.YOURDATA_SETTINGS]
        .some(route => pathname.startsWith(route));

    let tittle;

    if (!isSelected) {
        tittle = "Ajustes";
    }
    else {
        if (pathname?.includes(ROUTES.ORGANIZATIONS_SETTINGS)) {
            if (pathname?.includes(ROUTES.GROUP_SETTINGS)) {
                tittle = "Grupos";
            } else {

                tittle = selectedOrg?.name
            }
        }
    }
    return (
        <div style={{ minHeight: globals.ALTURA_TOPNAV }} className='flex justify-start sm:justify-between items-center px-4 border-b-[1px] border-NeutralNeutral5 gap-2 bg-white'>
            <button className='hidden sm:flex gap-4 justify-center items-center' onClick={() => navigate(ROUTES.VISOR_DOCUMENTOS)}>
                {/* <img src={EducaSignLogo} alt="Imagen organizacion" className="w-full h-full" /> */}

                <ReturnIcon />
                <p className="button16 text-NeutralNeutral1">
                    {selectedOrg?.name}
                </p>
            </button>
            <button className='sm:hidden' onClick={() => navigate(-1)}>
                <ReturnIcon />
            </button>
            <p className='hidden sm:block button16 text-NeutralNeutral1'>
                Ajustes
            </p>
            <p className='button16 text-NeutralNeutral1 sm:hidden'>
                {tittle}
            </p>
            <div className='hidden sm:block'>
                <UserAvatar />
            </div>
        </div>

    )
}
