import React, { useState } from 'react'
import InputField from '../../../../shared/components/InputField'
import { Toggle } from '../../../../shared/components/Toggle';
import Button, { ButtonSize } from '../../../../shared/components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { OrganizationSettingResponse } from '../../types/OrganizationSettingType';
import { useOrganizationSettingsContext } from '../../context/OrganizationSettingsContext'; 2
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../../../shared/api/Axios';
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi';
import { ShowCustomToast } from '../../../../shared/components/ShowCustomToast';
import { ModalTwoOptions } from '../../../../shared/components/ModalTwoOptions';

interface createGroupType {
    name: string;
    UsersOrganizationsId: string[];
}

interface InfoToCreateGroup {
    users: UserInfoToAddGroup[]
}

interface UserInfoToAddGroup {
    id: string,
    name: string,
    role: string,

}


export const AddGroupSettingOrganization = () => {
    const [nameGroup, setNameGroup] = useState('');
    const [usersSelectes, setUsersSelected] = useState<string[]>([])
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isModalOpen, setModalToggle] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

    const handleToggleChange = (userId: string) => {
        setSelectedUsers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const fetchInfoToCreateGroup = async (): Promise<InfoToCreateGroup> => {
        const response = await api.get(SettingsEndpoints.GetInfoToCreateGroup())
        return response.data
    }

    const { data: InfoToCreateGroup } = useQuery({
        queryKey: ['InfoToCreateGroup'],
        queryFn: fetchInfoToCreateGroup
    })

    const createGroupAsync = async () => {
        const createGroupRequest = {
            name: nameGroup,
            usersOrganizationsId: selectedUsers
        };
        await api.post(SettingsEndpoints.CreateGroup(), createGroupRequest)
    }
    const CreateGroupMutation = useMutation({
        mutationFn: createGroupAsync,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["groupsSettings"] })

            ShowCustomToast({
                title: "Guardado correctamente",
                message: "Los cambios se han guardado correctamente",
                icon: "success",
            });
        }
        ,
        onError: (error) => {
            ShowCustomToast({
                title: "Error al guardar",
                message: "Ha ocurrido un error al guardar los cambios",
                icon: "error",
            })
            console.error("Error updating general settings:", error)
        },
    })

    const SaveChanges = () => {
        if (nameGroup.length === 0) {
            ShowCustomToast({
                title: "Error",
                message: "El nombre del grupo no puede estar vacío",
                icon: "error",
            })
            return
        }
        CreateGroupMutation.mutateAsync()
        navigate(-1)
    }


    return (
        <>
            <div className='flex flex-col gap-4 p-4 h-full'>

                <div className='flex flex-col gap-4'>
                    <p className='button16 text-NeutralNeutral1'>Nombre del grupo</p>
                    <InputField
                        type='text'
                        placeholder='Nombre del grupo'
                        value={nameGroup}
                        onChange={(e) => setNameGroup(e.target.value)}
                        withBorder={true}
                    />
                </div>
                <div className='flex flex-col gap-4'>
                    <p className='button16 text-NeutralNeutral1'>Seleccionar usuarios</p>
                    <div className='flex flex-col gap-2'>
                        {
                            InfoToCreateGroup?.users?.map((user) => (
                                <div key={user.id} className='flex flex-row gap-2'>
                                    <Toggle
                                        isChecked={selectedUsers.includes(user.id)}
                                        onChange={() => handleToggleChange(user.id)}
                                    />
                                    <p className='bodyl text-NeutralNeutral2'>{user.name} ({user.role})</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='mt-auto flex justify-end'>
                    <Button
                        text='Crear grupo'
                        onClick={() => setModalToggle(true)}
                        size={ButtonSize.medium}
                        hasBg={true}
                    />
                </div>

                {
                    isModalOpen &&
                    <ModalTwoOptions
                        title="¿Estas seguro?"
                        isOpen={isModalOpen}
                        onClose={() => setModalToggle(false)}
                        onConfirm={SaveChanges}
                        cancelText="Cancelar"
                        confirmText="Confirmar"
                    />
                }
            </div>
        </>
    )
}
