import React from "react";

interface SettingsIconProps {
    size?: number;
    strokeWidth?: number;
    strokeColor?: string;
    className?: string;
    title?: string;
}

const SettingsIcon: React.FC<SettingsIconProps> = ({
    size = 18,
    strokeWidth = 1.5,
    strokeColor = "#94A3B8",
    className = "",
    title = "Settings",
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            aria-hidden={!title}
            role={title ? "img" : undefined}
            aria-label={title || undefined}
        >
            {title && <title>{title}</title>}
            <path
                d="M7.61883 2.28338C7.69417 1.83132 8.08529 1.5 8.54357 1.5H9.45521C9.9135 1.5 10.3046 1.83132 10.38 2.28338L10.5041 3.02822C10.563 3.38178 10.8238 3.66532 11.1548 3.80276C11.486 3.94027 11.8663 3.9219 12.1581 3.71348L12.7727 3.27445C13.1457 3.00807 13.6565 3.05035 13.9806 3.37441L14.6252 4.01903C14.9492 4.34309 14.9915 4.85393 14.7252 5.22686L14.286 5.84172C14.0776 6.13343 14.0592 6.51361 14.1967 6.84469C14.3341 7.17565 14.6176 7.43636 14.971 7.49527L15.716 7.61944C16.1681 7.69478 16.4994 8.0859 16.4994 8.54418V9.45582C16.4994 9.9141 16.1681 10.3052 15.716 10.3806L14.9712 10.5047C14.6176 10.5636 14.3341 10.8244 14.1966 11.1554C14.0591 11.4866 14.0775 11.8669 14.2859 12.1587L14.7249 12.7732C14.9912 13.1461 14.949 13.657 14.6249 13.981L13.9803 14.6256C13.6562 14.9497 13.1454 14.992 12.7725 14.7256L12.1578 14.2866C11.8661 14.0782 11.4858 14.0598 11.1547 14.1973C10.8238 14.3347 10.563 14.6182 10.5041 14.9717L10.38 15.7166C10.3046 16.1687 9.9135 16.5 9.45521 16.5H8.54357C8.08529 16.5 7.69417 16.1687 7.61883 15.7166L7.49469 14.9718C7.43576 14.6182 7.17499 14.3347 6.84396 14.1972C6.51279 14.0597 6.13251 14.0781 5.84072 14.2865L5.22604 14.7256C4.85312 14.992 4.34227 14.9497 4.01822 14.6256L3.37359 13.981C3.04953 13.6569 3.00725 13.1461 3.27363 12.7732L3.71282 12.1583C3.92119 11.8666 3.93956 11.4864 3.8021 11.1553C3.6647 10.8244 3.38122 10.5636 3.02774 10.5047L2.28277 10.3806C1.83071 10.3052 1.49939 9.9141 1.49939 9.45582V8.54418C1.49939 8.0859 1.83071 7.69478 2.28277 7.61944L3.0276 7.4953C3.38117 7.43637 3.66471 7.1756 3.80216 6.84456C3.93966 6.51337 3.9213 6.13308 3.71287 5.84128L3.27396 5.2268C3.00758 4.85388 3.04986 4.34304 3.37392 4.01898L4.01854 3.37435C4.3426 3.0503 4.85344 3.00802 5.22637 3.27439L5.84103 3.71344C6.13276 3.92181 6.51295 3.94018 6.84406 3.80272C7.17503 3.66531 7.43575 3.38182 7.49467 3.02833L7.61883 2.28338Z"
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5 9.00002C11.5 10.3807 10.3807 11.5 8.99997 11.5C7.61926 11.5 6.49997 10.3807 6.49997 9.00002C6.49997 7.61931 7.61926 6.50002 8.99997 6.50002C10.3807 6.50002 11.5 7.61931 11.5 9.00002Z"
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SettingsIcon;
