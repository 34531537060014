import { Route } from "react-router-dom";
import { ROUTES } from '../../router/routes';
import { AllTemplatesPage } from '../pages/AllTemplatesPage';

export const TemplatesRoutes = () => {
    return (
        <>
            <Route path={ROUTES.ALL_TEMPLATES} element={<AllTemplatesPage />} />

        </>
    )
}
