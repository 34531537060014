export const ToPdfFromString = (base64String: string) => {
  // Convertir base64 a File
  const binaryString = window.atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes.buffer], { type: "application/pdf" });
  return new File([blob], "document.pdf", { type: "application/pdf" });
};
