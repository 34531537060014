import React from 'react'
import { Outlet } from 'react-router-dom'
import { ConfigurationTopNav } from '../navs/ConfigurationTopNav'

export const ConfigurationLayout = () => {
    return (
        <div className='flex flex-col h-lvh bg-NeutralNeutral6'>
            <ConfigurationTopNav />
            <Outlet />
        </div>
    )
}
