import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'
import api from '../../../../shared/api/Axios'
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi'
import { Skeleton } from '../../../../shared/components/Skeleton'
import { GroupsSettingType } from '../../types/OrganizationSettingType'
export const GruposSettingsOrganization = () => {

    const fetchGroupsSettings = async (): Promise<GroupsSettingType> => {
        const response = await api.get(SettingsEndpoints.GetOrganizationGroups())
        return response.data;
    }

    const { data: groupsSettings, isLoading } = useQuery({
        queryKey: ['groupsSettings'],
        queryFn: fetchGroupsSettings
    })

    return (
        <>
            {isLoading ? (
                <div className="max-w-lg size-full">
                    {Array(3)
                        .fill(0)
                        .map((_, i) => <Skeleton key={i} />)}
                </div>
            )
                :
                <div className='flex flex-col size-full'>
                    <div className='flex justify-between items-center py-2'>
                        <p className='bodyl text-NeutralNeutral2'>{groupsSettings?.groupCount} grupos</p>
                        <Link to={`${ROUTES.ADD_GROUP_SETTINGS}`} className='button16 text-CorporatePrimary'>
                            + Añadir grupo
                        </Link>
                    </div>
                    <div className='flex-1 size-full '>
                        <div className='flex flex-col gap-4 '>

                            {
                                groupsSettings?.groups.map((group) => {
                                    return (
                                        <Link to={`${ROUTES.GROUP_SETTINGS}/${group.id}`} className='block' key={group.id}>
                                            <div className='border-2 border-NeutralNeutral5 rounded-lg p-4 hover:border-CorporatePrimary'>
                                                <div className='flex flex-col gap-2'>
                                                    <div className='flex justify-between items-center'>
                                                        <p className='bodymbold text-NeutralNeutral1'>{group.name}</p>

                                                    </div>
                                                    <p className='bodym text-NeutralNeutral2'>{group.memberShip} miembros</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            }
        </>
    )
}
