import { useState } from "react"

export const usePagination = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [numPages, setNumPages] = useState<number>(0)


    const onDocumentLoadSuccess = (numPages: number) => {
        setNumPages(numPages)
        // setCurrentPage(prevPage => prevPage !== 1 ? prevPage : 1);
    }

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage)
    }

    return { currentPage, numPages, onDocumentLoadSuccess, onPageChange, setCurrentPage }
}