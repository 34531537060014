import { useDeveloperContext } from '../context/DeveloperContext';
import { useApiDeveloper } from '../hooks/useApiDeveloper';

export const AssignRoleToUserModal = () => {
    const { setIsAssignRoleToUserModalOpen, setSelectedRole, selectedUserId, selectedOrganization, setSelectedRoleToAddToUser, selectedRoleToAddToUser } = useDeveloperContext();
    const { assignRoleToUserMutation } = useApiDeveloper();
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="bodylbold text-NeutralNeutral1 mb-4">Asignar role a usuario: {selectedUserId}</h2>
                <select
                    className="w-full p-2 border rounded mb-4"
                    onChange={(e) => setSelectedRoleToAddToUser(Number(e.target.value))}
                >
                    <option value="">Elige role</option>
                    {selectedOrganization?.roles.map((role) => (
                        <option key={role.id} value={role.id}>
                            {role.name}
                        </option>
                    ))}
                </select>
                <div className="flex justify-end mt-2">
                    <button className="px-4 py-2 bg-gray-200 rounded mr-2" onClick={() => setIsAssignRoleToUserModalOpen(false)}>
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={() => {
                            assignRoleToUserMutation.mutate({ userId: selectedUserId, roleId: selectedRoleToAddToUser! })
                            setIsAssignRoleToUserModalOpen(false)
                        }}
                        disabled={assignRoleToUserMutation.isPending}
                    >
                        Añadir
                    </button>
                </div>
            </div>
        </div>
    )
}
