import { ROUTES } from "../../router/routes";
import NotificationIcon from "../../shared/Icons/NotificationIcon";
import { OrganizationIcon } from "../../shared/Icons/OrganizationIcon";
import ShieldCheckIcon from "../../shared/Icons/ShieldCheckIcon";
import UserIcon from "../../shared/Icons/UserIcon";

export interface itemSetting {
  id: number;
  label: string;
  route: string;
  icon: React.ElementType;
}

export const AllSettings: itemSetting[] = [
  {
    id: 1,
    label: "Tus datos",
    route: ROUTES.YOURDATA_SETTINGS,
    icon: UserIcon,
  },
  {
    id: 2,
    label: "Organización",
    route: ROUTES.ORGANIZATIONS_SETTINGS,
    icon: OrganizationIcon,
  },
  {
    id: 3,
    label: "Certificados",
    route: ROUTES.CERTIFICATIONS_SETTINGS,
    icon: ShieldCheckIcon,
  },
  {
    id: 4,
    label: "Notificaciones",
    route: ROUTES.NOTIFICATIONS_SETTINGS,
    icon: NotificationIcon,
  },
];
