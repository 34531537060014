import { Permission, Permissions } from "../../auth/types/Permissions";
import { ROUTES } from "../routes";

type HasAccessFunction = (permission: Permission) => boolean | undefined;

export const getInitialRoute = (hasAccess: HasAccessFunction): string => {
  if (hasAccess(Permissions.DocumentsRead)) {
    return ROUTES.VISOR_DOCUMENTOS;
  } else if (hasAccess(Permissions.TemplatesRead)) {
    return ROUTES.ALL_TEMPLATES;
  } else if (hasAccess(Permissions.StatisticsRead)) {
    return ROUTES.ESTADISTICAS;
  }

  return ROUTES.VISOR_DOCUMENTOS;
};
