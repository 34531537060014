import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import api from '../../../shared/api/Axios'
import { DocumentEndpoints } from '../../../shared/api/services/ConfigApi'
import Button, { ButtonSize } from '../../../shared/components/Button'
import { useDocumentosContext } from '../../context/DocumentosContext'
import { PendingDocument } from '../../types/DocumentsByUserRequest'

const BATCH_SIZE = 3

export interface FooterSignaturePreviewDocumentViewerProps {
    setCurrentDocumentFile: (document: string) => void;
    setModalOpen: (open: boolean) => void;
    currentDocumentIndex: number;
    setCurrentDocumentIndex: (index: any) => void;
    setCurrentDocument: (document: PendingDocument) => void;
}

export const FooterSignaturePreviewDocumentViewer = ({ setCurrentDocumentFile, setModalOpen, currentDocumentIndex, setCurrentDocumentIndex, setCurrentDocument }: FooterSignaturePreviewDocumentViewerProps) => {

    const { selectedDocuments } = useDocumentosContext()
    const queryClient = useQueryClient()
    let min = Math.floor(currentDocumentIndex / BATCH_SIZE) * BATCH_SIZE;
    let max = Math.min((Math.floor(currentDocumentIndex / BATCH_SIZE) + 1) * BATCH_SIZE, selectedDocuments.length);
    const fetchDocumentBatch = useCallback(
        async (startIndex: number, endIndex: number) => {
            const response = await api.post<string[]>(
                DocumentEndpoints.GetFiles,
                selectedDocuments.map(doc => doc.id).slice(startIndex, endIndex),
            )
            return response.data
        },
        [selectedDocuments],
    )

    const {
        data: documentBatches,
        error,
        isLoading,
        isFetching,
        refetch
    } = useQuery({
        queryKey: ["getDocumentsToSign", Math.floor(currentDocumentIndex / BATCH_SIZE)],
        queryFn: () =>
            fetchDocumentBatch(
                min,
                max,
            ),
        staleTime: Number.POSITIVE_INFINITY,
        refetchOnMount: false,
        enabled: false,
    })

    useEffect(() => {
        if (currentDocumentIndex === 0) {
            refetch()
        }
    }, [currentDocumentIndex, refetch])

    useEffect(() => {
        if (currentDocumentIndex % BATCH_SIZE === 2 && currentDocumentIndex + 1 < selectedDocuments.length) {
            const nextBatchIndex = Math.floor((currentDocumentIndex + 1) / BATCH_SIZE)
            queryClient.prefetchQuery({
                queryKey: ["getDocumentsToSign", nextBatchIndex],
                queryFn: () =>
                    fetchDocumentBatch(
                        nextBatchIndex * BATCH_SIZE,
                        Math.min((nextBatchIndex + 1) * BATCH_SIZE, selectedDocuments.length),
                    ),
            })
        }
    }, [currentDocumentIndex, queryClient, selectedDocuments, fetchDocumentBatch])


    if (error) {
        return <div>Error loading documents</div>
    }
    const currentBatchIndex = Math.floor(currentDocumentIndex / BATCH_SIZE)
    const currentDocumentInBatch = currentDocumentIndex % BATCH_SIZE;

    useEffect(() => {
        if (documentBatches && documentBatches[currentDocumentInBatch]) {
            setCurrentDocumentFile(documentBatches[currentDocumentInBatch])
        } else {
            setCurrentDocumentFile('')
        }

        const selectedDoc = selectedDocuments.slice(min, max)[currentDocumentInBatch]
        if (selectedDoc) {
            setCurrentDocument(selectedDoc)
        }
    }, [documentBatches, currentDocumentInBatch, selectedDocuments, min, max, setCurrentDocumentFile, setCurrentDocument])

    // setCurrentDocumentFile(documentBatches != undefined ? documentBatches[currentDocumentInBatch] : '')
    // setCurrentDocument(selectedDocuments.slice(min, max)[currentDocumentInBatch])

    const handleNext = () => {
        setCurrentDocumentIndex((prevIndex: number) => {
            const nextIndex = prevIndex + 1
            return nextIndex < selectedDocuments.length ? nextIndex : prevIndex
        })
    }

    const handlePrevious = () => {
        setCurrentDocumentIndex((prevIndex: number) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    }

    const allReadyToSign = currentDocumentIndex === selectedDocuments.length - 1
    const oneDocumentToSign = selectedDocuments.length === 1
    return (
        <div className="flex flex-col gap-2 p-2 bg-white md:flex-row">
            <div className='hidden md:flex-1'></div>
            {
                !oneDocumentToSign &&
                <div className="flex justify-between gap-2 md:justify-center md:flex-1 ">
                    <div className={`md:size-fit  size-full`}>

                        <Button
                            text="Anterior"
                            size={ButtonSize.medium}
                            hasBg={false}
                            hasBorder={false}
                            onClick={handlePrevious}
                            isDisabled={currentDocumentIndex === 0}

                        />
                    </div>
                    <div className='size-full md:size-fit'>

                        <Button
                            text="Siguiente"
                            size={ButtonSize.medium}
                            hasBg={true}
                            onClick={handleNext}
                            isDisabled={currentDocumentIndex >= selectedDocuments.length - 1}
                        />
                    </div>
                </div>
            }

            <div className="flex justify-center items-center size-full md:justify-end">
                <div className='md:size-fit size-full '>

                    <Button
                        // text={`${oneDocumentToSign
                        //     ? 'Firmar'
                        //     : allReadyToSign
                        //         ? "Firmar todos"
                        //         : "Firmar todos sin leer"
                        //     }`}
                        text={`${oneDocumentToSign
                            ? 'Firmar'
                            : "Firmar todos"
                            }`}
                        size={ButtonSize.medium}
                        hasBorder={true}
                        // hasBg={allReadyToSign ? true : false}
                        hasBg={true}
                        onClick={() => setModalOpen(true)}
                    />
                </div>
            </div>
        </div>
    )
}