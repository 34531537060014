import React from 'react'
import { Route } from 'react-router-dom'
import { DocumentosContextProvider } from '../context/DocumentosContext'
import { DocumentsLayout } from '../layouts/DocumentsLayout'
import { ROUTES } from '../../router/routes'
import { VisorDocumentos } from '../components/VisorDocumentos'
import { Faqs } from '../../faqs/pages/Faqs'
import { EducaSignLayout } from '../../shared/layouts/EducaSignLayout'
import { SignaturePreviewPage } from '../pages/SignaturePreviewPage'

export const DocumentsRoutes = () => {
    return (
        <>
            <Route element={<DocumentosContextProvider />}>
                <Route element={<DocumentsLayout />}>
                    <Route
                        path={ROUTES.VISOR_DOCUMENTOS}
                        element={<VisorDocumentos />}
                    />
                </Route>
                <Route path={ROUTES.FAQS} element={<Faqs culture='Spanish' />} />
                <Route path={ROUTES.SIGNATURE_PREVIEW} element={<SignaturePreviewPage />} />
            </Route>

        </>
    )
}


