import React from 'react'
import { useGetTemplates } from '../../hooks/AllTemplates/useGetTemplates'
import { ItemListTemplate } from './ItemListTemplate'

export const ListTemplates = () => {
    const { templates } = useGetTemplates()
    return (
        <div className='grid grid-cols-4 items-center justify-center gap-4 size-full'>

            {templates.map((template, index) =>
                <ItemListTemplate
                    key={template.id}
                    index={index}
                    template={template} />
            )}
        </div>
    )
}
