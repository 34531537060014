import React from 'react'
import { Template } from '../../types/templates'
import { DotsDropDown } from '../../../shared/components/DotsDropDown'
import { EditIcon } from '../../../shared/Icons/EditIcon'
import { FileIcon } from '../../../shared/Icons/FilenIcon'
import TrashIcon from '../../../shared/Icons/TrashIcon'
import { usePermissions } from '../../../auth/hooks/usePermission'
import { Permissions } from '../../../auth/types/Permissions'

export interface ItemListTemplateProps {
    template: Template
    index: number
}

export const ItemListTemplate = ({ index, template }: ItemListTemplateProps) => {

    const { hasAccess } = usePermissions()
    const items = [
        {
            name: 'Editar plantilla',
            icon: <FileIcon />,
            requiredPermission: Permissions.TemplatesEdit
        },
        {
            name: 'Cambiar nombre',
            icon: <EditIcon />,
            requiredPermission: Permissions.TemplatesEdit

        },
        {
            name: 'Borrar',
            icon: <TrashIcon />,
            requiredPermission: Permissions.TemplatesDelete
        }
    ].filter(item => !item.requiredPermission || hasAccess(item.requiredPermission));

    return (
        <button className='h-28 flex flex-col p-4 gap-2 bg-white border-2 border-NeutralNeutral5'>
            <div className='flex size-full h-1/3 justify-between items-center bodymbold text-NeutralNeutral1'>
                {template.name}
                {/* <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">Sin Destinarios</span> */}
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">Con Destinarios</span>
                {items.length > 0 && <DotsDropDown items={items} />}
            </div>
            <div className='h-2/3'>
                <p className='bodym text-NeutralNeutral2'>{`${template.numberOfSignatures} firmas`}</p>
            </div>

        </button>
    )
}
