import { Outlet } from "react-router-dom";
import { NavDocumentos } from "../components/NavDocumentos";
import { Skeleton } from "../../shared/components/Skeleton";
import { useDocumentosContext } from "../context/DocumentosContext";

export const DocumentsLayout = (props: any) => {
  const { loadingDocuments } = useDocumentosContext();
  return (
    <div className="flex flex-col">
      <NavDocumentos />
      {
        loadingDocuments ? (
          <div className=' max-w-screen-lg w-full  m-auto '>
            {
              Array(3)
                .fill(0)
                .map((_, i) => <Skeleton key={i} />)
            }
          </div>

        ) :
          <Outlet />

      }
    </div>
  );
};
