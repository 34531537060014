import { useState, useRef, useEffect } from "react"
import { MoreHorizontal } from "lucide-react"
import { set } from "date-fns";

export type DotsDropDownItem = {
    name: string;
    icon: React.ReactNode;
    onClick?: () => void;
}

export interface DotsDropDownProps {
    items: DotsDropDownItem[];
}

export const DotsDropDown = ({ items }: DotsDropDownProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={(e) => {
                    e.preventDefault()
                    setIsOpen(!isOpen)

                }}
                className="w-full flex items-center justify-end p-2 hover:bg-gray-100 rounded-md transition-colors"
            >
                <MoreHorizontal className="h-5 w-5 text-NeutralNeutral2" />
            </button>

            {isOpen && (
                <ul className="absolute right-0 mt-1 w-auto bg-white rounded-md shadow-lg border  py-1 overflow-visible z-50">
                    {items.map((item, index) => (
                        <li
                            key={index}
                            className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-3 border-b last:border-b-0`}
                            onClick={() => {
                                item.onClick?.();
                                setIsOpen(false)
                            }
                            }
                        >
                            {item.icon}
                            <p className="flex-grow w-[80%] bodylbold text-NeutralNeutral2 whitespace-nowrap text-left">{item.name}</p>
                        </li>
                    ))}

                </ul>
            )}
        </div>
    )
}

