import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useParams } from "react-router-dom";
import { ValidationDocumentResponse } from "../types/ValidationDocumentResponse";
import { DocumentEndpoints } from "../../shared/api/services/ConfigApi";
import api from "../../shared/api/Axios";
import Button, { ButtonSize } from "../../shared/components/Button";
import { Skeleton } from "../../shared/components/Skeleton";
import ShieldIcon from "../../shared/Icons/ShieldIcon";
import ClockIcon from "../../shared/Icons/ClockIcon";
import { TranslationKeys } from "../../shared/utils/i18n/TranslationKeys";
import FormatearFechaATexto from "../../shared/utils/dates/FormatearFechaATexto";
import formatearFechaAHora from "../../shared/utils/dates/formatearFechaAHora";
import EducaSignLogo from "../../assets/icons/EducaSignLogo.svg";
import Check from "../../assets/icons/check-circle.svg";
import { PDFViewer } from "../../shared/components/PdfViewer";
import { ToPdfFromString } from "../../shared/utils/ToPdfFromString";
import { usePagination } from "../../shared/hooks/usePagination";
// Definición del enum
const EnumValues = {
  EuroInnova: "3C183E17-A57C-427F-DF28-08DCED0F1008",
  EducaSign: "1613CE82-56B7-4A83-DF29-08DCED0F1008",
  Inesem: "1A414F2F-4E85-4D69-28C2-08DD07A8B55F",
  UNIVERSIDADCATÓLICANORDESTANA: "DFC2F9BB-58D8-4622-B07D-08DD07BCB96D",
  DEFAULT: 'DEFAULT'
};

export const ValidationPage = () => {
  const { t } = useTranslation();
  const { signProcessId } = useParams(); // Obtén ambos parámetros de la URL

  const { data: documentState, error, isLoading, isFetching, refetch } = useQuery<ValidationDocumentResponse>({
    queryFn: async () => {
      const response = await api.get<ValidationDocumentResponse>(DocumentEndpoints.getDocumentStatus(signProcessId));
      return response.data;
    },
    queryKey: ["documentStatus"],
  });


  const pdf = async () => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${documentState?.pdfBase64}`;
    link.download = `${documentState?.fileName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { numPages, currentPage, onDocumentLoadSuccess, onPageChange } = usePagination();

  return (
    <div className="h-screen w-screen ">
      <div className="flex flex-col  items-center w-full  h-full px-8 py-6 gap-3  md:flex md:flex-row md:justify-evenly  md:items-center ">
        <div className="flex flex-col gap-2 h-fit justify-center items-center w-full md:w-2/3 md:h-full">
          <div
            className="w-48 md:w-3/5 h-16 bg-no-repeat bg-contain bg-center">
            {documentState?.organizationLogoBase64 && documentState?.organizationLogoBase64.trim() !== "" ? (
              <img
                src={`data:image/svg+xml;base64,${documentState.organizationLogoBase64}`}
                alt="Logo de la organización"
                className="w-full h-full object-contain"
              />
            ) : (
              <img src={EducaSignLogo} alt="Imagen organizacion" className="w-full h-full" />
            )}
          </div>

          {documentState?.isDocumentValid != false && !error && !isLoading && (
            <div className="w-full md:h-[60vh] h-full ">
              {documentState?.pdfBase64 &&
                <PDFViewer
                  file={ToPdfFromString(documentState.pdfBase64)}
                  withZoom={true}
                  withDragging={true}
                  withDownload={false}
                  withStartYFinalPagination={false}
                  currentPage={currentPage}
                  numPages={numPages}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  onPageChange={onPageChange}
                />
                // (
                //   <iframe
                //     src={`data:application/pdf;base64,${documentState.pdfBase64}`}
                //     className="w-full h-full"
                //     title="Documento PDF"
                //   />
                // )
              }
            </div>
          )}
          {/* {documentState?.isDocumentValid != false && !error && !isLoading && (
            <div className="md:hidden my-2 h-[60vh] w-full overflow-hidden">
              {documentState?.pdfBase64 &&
                <PDFViewer
                  file={ToPdfFromString(documentState?.pdfBase64)}
                  withZoom={true}
                  withDragging={false}
                  withDownload={false}
                  withStartYFinalPagination={false}
                />
              } */}

          {/* <Button onClick={pdf}
                size={ButtonSize.medium}
                text={t(TranslationKeys.DescargarDocumento)}
                hasBg={true} /> */}
          {/* </div>)} */}


          <p className="h6 text-NeutralNeutral1 text-center">{documentState?.fileName}</p>
          <p className="bodym text-NeutralNeutral2  text-center">{signProcessId}</p>
        </div>
        <div className="w-[70px] h-[3px] bg-NeutralNeutral5 md:hidden">

        </div>
        <div className=" w-full h-fit md:w-auto md:h-full">
          {isLoading ? (
            <div className='w-full h-full flex justify-center items-center '>
              {
                Array(1)
                  .fill(0)
                  .map((_, i) => <Skeleton key={i} />)
              }
            </div>
          )
            : documentState?.isDocumentValid && documentState?.isDocumentSigned ? (
              <div className=" flex flex-col justify-center items-center h-full gap-2">
                <div className="  w-auto h-full flex flex-col items-center justify-center  gap-2">
                  <img src={Check} alt="check" className="" />
                  <span className="h6 text-NeutralNeutral1  text-center">{t(TranslationKeys.DocumentoFirmado)}</span>
                  <p className="bodym text-NeutralNeutral2 text-center"><Trans
                    i18nKey={`${TranslationKeys.SeEnvioElXyFirmadoElX}`}
                    values={{
                      creationDate: FormatearFechaATexto(documentState?.creationDate ? documentState.creationDate : null),
                      signedDate: FormatearFechaATexto(documentState?.signedDate ? documentState.signedDate : null),
                    }}
                    components={{ strong: <strong /> }}
                  /> </p>
                </div>

              </div>
            )
              : !documentState?.isDocumentValid ? (
                <div className=" flex flex-col items-center justify-center h-full gap-2">
                  <ShieldIcon size={50} aria-label="Security shield icon" />
                  <p className="h6 text-NeutralNeutral1  text-center">{t(TranslationKeys.DocumentoNoValido)}</p>
                  <p className="bodym text-NeutralNeutral2 text-center break-words ">{t(TranslationKeys.DocumentoNoEmitido)}</p>
                  <div className=" ">
                    <Button
                      size={ButtonSize.medium}
                      text={t(TranslationKeys.VolverAIntentar)}
                      onClick={() => refetch()}
                    />
                  </div>
                </div>
              ) : (
                <div className=" flex flex-col items-center justify-center h-full gap-2">
                  <ClockIcon />
                  <p className=" h6 text-NeutralNeutral1  text-center">{t(TranslationKeys.DocumentoPendienteDeFirmar)}</p>
                  <p className=" bodym text-NeutralNeutral2  text-center">
                    <Trans
                      i18nKey={`${TranslationKeys.DocumentoEnviadoConFecha}`}
                      values={{ date: FormatearFechaATexto(documentState?.creationDate ? documentState.creationDate : null), time: formatearFechaAHora(documentState?.creationDate ? documentState.creationDate : null) }}
                      components={{ strong: <strong />, br: <br /> }}
                    />
                  </p>
                </div>

              )}
        </div>
      </div>
    </div>
  );
}