import { useDocumentosContext } from '../context/DocumentosContext';
import { DocumentoGroupByFecha } from './DocumentoGroupByFecha';


export const ListaDocumentos = () => {
    const { documentosFiltradosGrouped } = useDocumentosContext();

    return (
        <div className='md: max-w-screen-lg items-center '>
            {documentosFiltradosGrouped.map((d, index) => (
                <DocumentoGroupByFecha key={index}
                    fecha={d.createdDay.toString()}
                    documentos={d.documents} />
            ))}
        </div>
    )
}