import React from "react";
import { DocumentListResponse } from "../types/DocumentsByUserRequest";
import api from "../../shared/api/Axios";
import { DocumentEndpoints } from "../../shared/api/services/ConfigApi";
import { useQuery } from "@tanstack/react-query";

export const useFetchDocuments = (
  pageAll: number,
  pageCompleted: number,
  pageInProgress: number,
  pagePending: number,
  pageCanceled: number,
  pageSize: number,
  search: string | null,
) => {
  const getDocuments = async (
    pageAll: number,
    pageCompleted: number,
    pageInProgress: number,
    pagePending: number,
    pageCanceled: number,
    pageSize: number,
    search: string | null,
  ): Promise<DocumentListResponse> => {
    if (search) {
      const response = await api.get(
        DocumentEndpoints.getDocumentList(
          pageAll,
          pageCompleted,
          pagePending,
          pageInProgress,
          pageCanceled,
          pageSize,
          search,
        ),
      );
      return response.data;
    } else {
      const response = await api.get(
        DocumentEndpoints.getDocumentList(
          pageAll,
          pageCompleted,
          pagePending,
          pageInProgress,
          pageCanceled,
          pageSize,
        ),
      );
      return response.data;
    }
  };

  const { data, error, isLoading, isFetching, refetch } =
    useQuery<DocumentListResponse>({
      queryFn: () =>
        getDocuments(
          pageAll,
          pageCompleted,
          pageInProgress,
          pagePending,
          pageCanceled,
          pageSize,
          search,
        ),
      queryKey: [
        "documentList",
        pageAll,
        pageCompleted,
        pageInProgress,
        pagePending,
        pageCanceled,
        pageSize,
        search,
      ],
      enabled: false,
    });

  return { data, error, isLoading, isFetching, refetch };
};
