import { Navigate, Route } from 'react-router-dom';
import { PrivateRoutes } from '../../router/PrivateRoutes';
import { ROUTES } from '../../router/routes';
import { getInitialRoute } from '../../router/utils/GetInitialRoute';
import { TopNavUnauthenticatedLayout } from '../../shared/layouts/TopNavUnauthenticatedLayout';
import { useAuthContext } from '../context/AuthContext';
import { usePermissions } from '../hooks/usePermission';
import { ExternalLoginPage } from '../pages/ExternalLoginPage';
import { LoginErrorPage } from '../pages/LoginErrorPage';

export const AuthRoutes = () => {
    const { isAuthenticated, isAuthLoading } = useAuthContext();
    const { hasAccess, isLoaded: isPermissionsLoaded } = usePermissions();
    return (
        <>
            <Route
                path={ROUTES.EXTERNALLOGIN}
                element={
                    !isAuthenticated || !isPermissionsLoaded ? (
                        <ExternalLoginPage />
                    ) : (
                        <Navigate to={getInitialRoute(hasAccess)} replace />
                    )
                } />

            <Route element={
                !isAuthLoading && (
                    <TopNavUnauthenticatedLayout
                        backButtonVisible={false}
                        loginButtonVisible={false}
                    />
                )
            }>
                <Route path={ROUTES.LOGINERROR} element={<LoginErrorPage />} />
            </Route>

            <Route
                path="*"
                element={
                    isAuthenticated || isPermissionsLoaded ? (
                        <PrivateRoutes />
                    ) : (
                        <Navigate to={ROUTES.EXTERNALLOGIN} replace />
                    )
                }
            />
        </>
    )
}
