import type React from "react"
import { PDFViewer } from "../../shared/components/PdfViewer"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { useFileTemplate } from "../hooks/useFileTemplate"
import { useSignature } from "../hooks/useSignature"
import { DraggableBoxQR } from "./ui/DraggableBoxQR"
import { DraggableBoxSignature } from "./ui/DraggableBoxSignature"
import { DraggableBoxURLValidation } from "./ui/DraggableBoxURLValidation"

export const DocumentTemplateViewer: React.FC = () => {
    const {
        fileTemplate,
        isUrlValidacion,
        isAñadirTexto,
        isQR,
        numPages,
        currentPage,
        onDocumentLoadSuccess,
        onPageChange
    } = useDocumentTemplateContext()

    const { firmantes, handleSizeSignatureChange, handleCoordinatesSignatureChange } = useSignature()
    const { handlePageLoadSuccess } = useFileTemplate()
    return (
        <div className="size-full flex-grow justify-center items-center">
            <PDFViewer
                file={fileTemplate[0]}
                handlePageLoadSuccess={handlePageLoadSuccess}
                currentPage={currentPage}
                numPages={numPages}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                onPageChange={onPageChange}
            >
                <div className="absolute inset-0 flex items-center justify-center">
                    {firmantes.map(
                        (firmante, index) =>
                            currentPage === firmante.page && (
                                <DraggableBoxSignature
                                    key={index}
                                    index={index}
                                    signature={firmante}
                                    currentPage={currentPage}
                                    onSizeChange={(value) => {
                                        handleSizeSignatureChange(index, value)
                                    }}

                                    onCoordinatesChange={(value) => {
                                        handleCoordinatesSignatureChange(index, value)
                                    }}

                                />
                            ),
                    )}
                    {(isUrlValidacion || isAñadirTexto) && (
                        <DraggableBoxURLValidation />
                    )}
                    {isQR &&
                        <DraggableBoxQR />}
                </div>
            </PDFViewer>
        </div>

    )
}

