import React from 'react'
import { useApiDeveloper } from '../hooks/useApiDeveloper';
import { useDeveloperContext } from '../context/DeveloperContext';
import { OrganizationResponseDeveloper } from '../types/OrganizationResponseDeveloper';
import CloseIcon from '../../shared/Icons/CloseIcon';
import { PenLine } from "lucide-react"


export const OrganizationsInfo = ({ organization }: { organization: OrganizationResponseDeveloper }) => {
    const { setSelectedOrganization, selectedRole, setSelectedRole, setIsAddRoleToOrganization, setIsAddPermissionToRoleModalOpen, setIsAssignRoleToUserModalOpen, setSelectedUserId } = useDeveloperContext();
    const { deleteRoleMutation, deletePermissionMutation, removePermissionFromRoleMutation, addPermissionToRoleMutation } = useApiDeveloper();

    const onRemoveRole = (id: number) => {
        if (window.confirm("¿Esta seguro que desea eliminar este role?")) {
            deleteRoleMutation.mutate(id)
        }
    }

    const onRemovePermissionFromRole = (roleId: number, permissionId: number) => {
        if (window.confirm("Esta seguro que desea eliminar este permiso en este rol?")) {
            removePermissionFromRoleMutation.mutate({ roleId, permissionId })
        }
    }

    return (
        <div className='flex flex-col gap-2 mb-2 p-2 border-2 rounded-md  w-full border-black'
            onClick={() => setSelectedOrganization(organization)}>
            <p className='text-left text-NeutralNeutral1 bodylbold'>Organizacion: </p>
            <div className='flex size-fit p-1 bg-slate-50 items-center gap-3'>

                <p className='text-left text-NeutralNeutral2 bodym'>{organization.name}</p>
                <p className='text-left text-NeutralNeutral2 bodym'>{organization.id}</p>
                <p className='text-left text-NeutralNeutral2 bodym'>Fecha de creacion: {organization.createdDate.toString()}</p>

            </div>
            <p className=' text-NeutralNeutral1 bodylbold'>Usuarios:</p>
            <div className='grid grid-cols-3 gap-4 '>

                {
                    organization.users.map((user) => (
                        <div key={user.id} className="flex flex-col gap-2 size-full bg-slate-50 p-1"
                            onClick={() => setSelectedUserId(user.id)}>
                            <div className='flex gap-2'>

                                <p className="text-left text-NeutralNeutral2 bodym underline">
                                    {user.name}
                                </p>
                                <p className="text-left text-NeutralNeutral2 bodym">({user.id})</p>
                            </div>
                            <div className="flex gap-2">
                                <button
                                    onClick={() => {
                                        setIsAssignRoleToUserModalOpen(true)
                                    }}>
                                    <PenLine size={20} color='#32BB78' />
                                </button>
                                <p className="text-left text-NeutralNeutral2 bodym">Rol: </p>
                                <p className="text-left text-NeutralNeutral2 bodym">{user.role}</p>
                            </div>
                        </div>
                    ))

                }
            </div>
            <div className='flex gap-2 items-center'>
                <p className=' text-NeutralNeutral1 bodylbold'>Roles:</p>
                <button
                    onClick={() => {
                        setIsAddRoleToOrganization(true)
                    }}
                    className={`bg-CorporatePrimary rounded-full size-4 flex items-center justify-center text-white`}
                >
                    <div className="w-[10px] h-[2px] bg-white absolute"></div>
                    <div className="w-[2px] h-[10px] bg-white absolute"></div>
                </button>

            </div>
            <div className='flex flex-wrap gap-2 '>
                {
                    organization.roles.map((role) =>
                        <div
                            key={role.id}
                            className='flex flex-col gap-2 bg-slate-50  min-w-min' >
                            <div className='flex justify-between px-2 items-center gap-2'>
                                <p className='text-left text-NeutralNeutral2 bodym'>{role.name}</p>
                                <div className='flex justify-center items-center gap-2'>

                                    {role.name !== 'Developer' &&

                                        <CloseIcon
                                            size={20}
                                            onClick={() => onRemoveRole(Number(role.id))}
                                            disabled={deleteRoleMutation.isPending} />
                                    }
                                    <button
                                        onClick={() => {
                                            setSelectedRole(role)
                                            setIsAddPermissionToRoleModalOpen(true)
                                        }}
                                        className={`bg-CorporatePrimary rounded-full size-4 flex items-center justify-center text-white`}
                                    >
                                        <div className="w-[10px] h-[2px] bg-white absolute"></div>
                                        <div className="w-[2px] h-[10px] bg-white absolute"></div>
                                    </button>
                                </div>
                            </div>
                            {
                                role.permissions.map((permission) =>
                                    <div key={permission.id} className='flex justify-between ml-2'>
                                        <p className='text-left text-NeutralNeutral2 bodym'>- {permission.name}</p>

                                        <CloseIcon
                                            size={20}
                                            onClick={() => onRemovePermissionFromRole(Number(role.id), Number(permission.id))}
                                            disabled={removePermissionFromRoleMutation.isPending} />
                                    </div>
                                )
                            }

                        </div>
                    )
                }
            </div>
        </div>
    )
}
