import { createContext, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { ROUTES } from '../../router/routes'

type SettingsContextType = {
    viendo?: string
    setViendo: (viendo: string) => void
}

export const filters = [ROUTES.YOURDATA_SETTINGS, ROUTES.ORGANIZATIONS_SETTINGS, ROUTES.CERTIFICATIONS_SETTINGS, ROUTES.NOTIFICATIONS_SETTINGS]


export const SettingsContext = createContext<SettingsContextType | undefined>(undefined)

export const SettingsProvider = () => {
    const [viendo, setViendo] = useState<typeof filters[number] | undefined>(undefined)
    return (
        <SettingsContext.Provider
            value={{
                viendo,
                setViendo,
            }}>
            <Outlet />
        </SettingsContext.Provider>
    )
}

export const useSettingsContext = () => {
    const context = useContext(SettingsContext)
    if (!context) {
        throw new Error('usenSettings must be used within a SettingsProvider')
    }
    return context
}
