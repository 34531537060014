import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ArchiveIcon, EditIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import api from '../../../../shared/api/Axios'
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi'
import { DotsDropDown } from '../../../../shared/components/DotsDropDown'
import { ModalTwoOptions } from '../../../../shared/components/ModalTwoOptions'
import SearchBar from '../../../../shared/components/SearchBar'
import { ShowCustomToast } from '../../../../shared/components/ShowCustomToast'
import { useForm } from '../../../../shared/hooks/useForm'
import { MembersSettingType } from '../../types/OrganizationSettingType'
import { Skeleton } from '../../../../shared/components/Skeleton'

export const MiembrosSettingsOrganization = () => {
    const [searchMembers, setSearchMembers] = useState("");
    const [isDeshabilitarOpen, setIsDeshabilitarOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>();
    const [isOldMembers, setIsOldMembers] = useState(false)
    const queryClient = useQueryClient()
    const { formState: membersFormState, onInputChange: onMembersInputChange, setFormState: setMembersFormState } = useForm<MembersSettingType>(
        {} as MembersSettingType,
    )
    const disabledOrActiveUserFromOrganization = useMutation({
        mutationFn: async (userId: string) => {
            return await api.delete(SettingsEndpoints.DisabledOrActiveUserFromOrganization(userId))
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["membersSettings"] })
            setIsDeshabilitarOpen(false)
            setSelectedUserId(null)
            ShowCustomToast({
                title: "Correcto",
                message: `{Usuario ${isOldMembers ? "habilitado" : "deshabilitado"} correctamente`,
                icon: "success",
            })
        },
        onError: (error) => {
            ShowCustomToast({
                title: "Error al guardar",
                message: `{Ha ocurrido al ${isOldMembers ? "deshabilitar" : "habilitar"} usuario`,
                icon: "error",
            })
        },
    })

    const fetchMembersSettings = async (): Promise<MembersSettingType> => {
        const response = await api.get(SettingsEndpoints.GetOrganizationMembers(searchMembers))
        return response.data;
    }

    const { data: membersSettings, isLoading } = useQuery({
        queryKey: ['membersSettings', searchMembers],
        queryFn: fetchMembersSettings
    })

    useEffect(() => {
        if (membersSettings) {
            setMembersFormState(membersSettings)
        }
    }, [membersSettings])

    const onConfirm = async () => {
        if (selectedUserId) {
            await disabledOrActiveUserFromOrganization.mutateAsync(selectedUserId)
        } else {
            ShowCustomToast({
                title: "Error",
                message: "No se ha seleccionado ningún usuario",
                icon: "error",
            })
        }
    }


    return (
        <>
            {isLoading ? (
                <div className="max-w-lg size-full">
                    {Array(3)
                        .fill(0)
                        .map((_, i) => <Skeleton key={i} />)}
                </div>
            )
                :
                <div className='flex flex-col justify-between gap-4 mt-4 size-full'>
                    <SearchBar
                        placeholder='Buscar'
                        onSearch={setSearchMembers}
                        search={searchMembers}
                    />
                    {/* {
                !isOldMembers &&
                <Link to={ROUTES.ADD_MEMBER_SETTINGS} >

                    <div className='flex justify-between items-center'>
                        <p className='bodyl text-NeutralNeutral2'>Miembros</p>
                        <button className='button16 text-CorporatePrimary'>
                            + Añadir miembro
                        </button>
                    </div>
                </Link>
            } */}

                    <div className="size-full overflow-auto ">
                        <div className="rounded-md  border-0">
                            <div className="relative w-full overflow-auto">
                                <table className="w-full caption-bottom ">
                                    <thead className="[&_tr]:border-b">
                                        <tr className="border-b transition-colors">
                                            <th className="h-12 px-4 text-left align-middle button16 text-NeutralNeutral1">Nombre</th>
                                            <th className="h-12 px-4 text-left align-middle button16 text-NeutralNeutral1">Email</th>
                                            {
                                                !isOldMembers &&
                                                <th className="h-12 px-4 text-left align-middle button16 text-NeutralNeutral1">Rol</th>
                                            }
                                            {
                                                !isOldMembers &&
                                                <th className="h-12 px-4 text-left align-middle button16 text-NeutralNeutral1">Desde</th>
                                            }
                                            {
                                                isOldMembers &&
                                                <th className="h-12 px-4 text-left align-middle button16 text-NeutralNeutral1">Fecha de baja</th>
                                            }
                                            <th className="h-12 w-[50px] px-4 align-middle"></th>
                                        </tr>
                                    </thead>

                                    {

                                        isOldMembers ?

                                            <tbody className="[&_tr:last-child]:border-0">
                                                {membersFormState?.oldMembers?.map((user, index) => (
                                                    <tr key={index} className="border-b transition-colors hover:bg-gray-50">
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.name}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.mail}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.deletedAt}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">
                                                            <DotsDropDown items={[
                                                                {
                                                                    name: 'Editar',
                                                                    icon: <EditIcon className='text-NeutralNeutral2' />,
                                                                    onClick: () => { },
                                                                },
                                                                {
                                                                    name: 'Habilitar',
                                                                    icon: <ArchiveIcon className='text-NeutralNeutral2' />,
                                                                    onClick: () => {
                                                                        setIsDeshabilitarOpen(true);
                                                                        setSelectedUserId(user.id)
                                                                    },
                                                                }
                                                            ]} />
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                            :
                                            <tbody className="[&_tr:last-child]:border-0">
                                                {membersFormState.members?.map((user, index) => (
                                                    <tr key={index} className="border-b transition-colors hover:bg-gray-50">
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.name}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.mail}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">
                                                            <span className={`${user.role === "Validando usuario" ? "text-NeutralNeutral3" : "text-gray-700"}`}>
                                                                {user.role}
                                                            </span>
                                                        </td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">{user.joinedSince}</td>
                                                        <td className="p-4 align-middle bodyl text-NeutralNeutral2">
                                                            <DotsDropDown items={[
                                                                {
                                                                    name: 'Editar',
                                                                    icon: <EditIcon className='text-NeutralNeutral2' />,
                                                                    onClick: () => { },
                                                                },
                                                                {
                                                                    name: 'Deshabilitar',
                                                                    icon: <ArchiveIcon className='text-NeutralNeutral2' />,
                                                                    onClick: () => {
                                                                        setIsDeshabilitarOpen(true);
                                                                        setSelectedUserId(user.id)
                                                                    },
                                                                }
                                                            ]} />
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                    }

                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='sticky bottom-0 bg-white w-full flex justify-center items-center p-4'>
                        <button
                            className='button16 text-CorporatePrimary '
                            onClick={() => setIsOldMembers(!isOldMembers)}>
                            {
                                isOldMembers ?
                                    "Ver miembros actuales" :
                                    "Ver antiguos miembros"
                            }
                        </button>
                    </div>
                    {
                        isDeshabilitarOpen &&
                        <ModalTwoOptions
                            title="¿Estas seguro?"
                            isOpen={isDeshabilitarOpen}
                            onClose={() => setIsDeshabilitarOpen(false)}
                            onConfirm={onConfirm}
                            cancelText="Cancelar"
                            confirmText="Confirmar"
                        />
                    }

                </div>
            }
        </>
    )
}
