import { useCallback } from "react";
import { useAuthContext } from "../context/AuthContext";
import { Permission } from "../types/Permissions";
import { Organization } from "../types/GetMeRequest";
import { Role } from "../types/Roles";

export const usePermissions = (org?: Organization) => {
    const { selectedOrg } = useAuthContext();
    const activeOrg = org || selectedOrg;

    const hasAccess = useCallback((permission: Permission) => {
        return activeOrg?.roleYPermissions?.permissions?.some(p => p.name === permission);
    }, [activeOrg]);

    const hasRole = useCallback((role: Role) => {
        return activeOrg?.roleYPermissions?.name === role;
    }, [activeOrg]);

    const isLoaded = !!activeOrg?.roleYPermissions?.permissions;

    return { hasAccess, isLoaded, hasRole };
};
