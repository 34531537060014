import type React from "react"
import { useParams } from "react-router-dom"
import { DocumentTemplateUploader } from "../components/DocumentTemplateUploader"
import { DocumentTemplateViewer } from "../components/DocumentTemplateViewer"
import { MenuDocumentTemplateManager } from "../components/MenuDocumentTemplateManager"
import { ValidationOptions } from "../components/ValidationOptions"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { globals } from "../../shared/constants/global"

export const CreateDocumentTemplatePage: React.FC = () => {
    const { nameTemplate } = useParams()
    const { fileTemplate } = useDocumentTemplateContext()

    return (
        <div
            className={`flex min-h-[calc(100vh-${globals.ALTURA_TOPNAV}px)] size-full overflow-hidden bg-NeutralNeutral6 p-2`}
        // style={{ maxHeight: "calc(100vh - 64px)" }}
        >
            <div
                className={`${fileTemplate.length > 0 ? "w-9/12" : "w-full"} p-4`}
            >
                {fileTemplate.length === 0
                    ? <DocumentTemplateUploader />
                    : <DocumentTemplateViewer />}
            </div>
            {fileTemplate.length > 0 && (
                <div className="flex flex-col gap-4 px-6 py-3 mt-1 bg-white rounded-md overflow-y-auto overflow-auto w-3/12">
                    <MenuDocumentTemplateManager />
                    <ValidationOptions />
                </div>
            )}
        </div>
    )
}

