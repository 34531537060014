interface ShieldCheckIconProps {
    className?: string
    size?: number
    color?: string
    border?: boolean
}

export default function ShieldCheckIcon({
    className = '',
    size = 20,
    color = 'currentColor',
    border = false
}: ShieldCheckIconProps) {
    return (
        <div className={`${border ? 'border border-NeutralNeutral5 rounded-full p-1' : ''} ${className} text-NeutralNeutral2`}>
            <svg
                width={size}
                height={Math.ceil(size * 22 / 20)} // Mantiene la proporción original
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 11.7496L9.25 13.9996L13 8.7496M10 1.71387C7.8495 3.75049 4.94563 4.99962 1.75 4.99962C1.69922 4.99962 1.64852 4.9993 1.59789 4.99867C1.2099 6.17878 1 7.43971 1 8.74966C1 14.3412 4.82432 19.0395 10 20.3716C15.1757 19.0395 19 14.3412 19 8.74966C19 7.43971 18.7901 6.17878 18.4021 4.99867C18.3515 4.9993 18.3008 4.99962 18.25 4.99962C15.0544 4.99962 12.1505 3.75049 10 1.71387Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    )
} 