import { AddPermissionModal } from '../components/AddPermissionModal';
import { AddPermissionToRoleModal } from '../components/AddPermissionToRoleModal';
import { AddRoleToOrganization } from '../components/AddRoleToOrganization';
import { AssignRoleToUserModal } from '../components/AssignRoleToUserModal';
import { OrganizationsInfo } from '../components/OrganizationsInfo';
import { PermissionsInfo } from '../components/PermissionsInfo';
import { useDeveloperContext } from '../context/DeveloperContext';
import { useApiDeveloper } from '../hooks/useApiDeveloper';

export const DevelopersPage = () => {

    const { fetchPermissionsData: permissions, fetchOrganizationsInfo: organizationsInfo } = useApiDeveloper();

    const { setIsAddPermissionModalOpen, setIsAddPermissionToRoleModalOpen, setIsAddRoleToOrganization, isAddRoleToOrganization, selectedOrganization, isAddPermissionToRoleModalOpen, isAddPermissionModalOpen, selectedRole, isAssignRoleToUserModalOpen } = useDeveloperContext();
    return (
        <>
            <h1 className='p-4 text-center text-NeutralNeutral1 bodyxlbold bg-CorporateCorp04'>Developers Page</h1>
            <div className='flex flex-wrap justify-around gap-4 p-4'>
                <div className='flex justify-start size-full '>
                    <div className='flex flex-col gap-2 size-fit bg-slate-50 p-2 border-black border-2 rounded-md'>
                        <div className='flex justify-between items-center'>
                            <h3 className='text-left text-NeutralNeutral2 bodylbold'>Todos los permisos</h3>
                            <button
                                onClick={() => setIsAddPermissionModalOpen(true)}
                                className={`bg-CorporatePrimary rounded-full size-4 flex items-center justify-center text-white`}
                            >
                                <div className="w-[10px] h-[2px] bg-white absolute"></div>
                                <div className="w-[2px] h-[10px] bg-white absolute"></div>
                            </button>
                        </div>
                        {
                            permissions?.map((permiso) => {
                                return <PermissionsInfo permiso={permiso} key={permiso.id} />
                            })
                        }
                    </div>
                </div>

                {

                    organizationsInfo && organizationsInfo.map((organization) =>
                        <OrganizationsInfo organization={organization} key={organization.id} />
                    )
                }
            </div>
            {
                isAddRoleToOrganization && selectedOrganization && (
                    <AddRoleToOrganization />
                )
            }
            {
                isAddPermissionToRoleModalOpen && selectedRole && (
                    <AddPermissionToRoleModal />
                )
            }
            {
                isAddPermissionModalOpen &&
                (
                    <AddPermissionModal />
                )
            }
            {
                isAssignRoleToUserModalOpen &&
                (
                    <AssignRoleToUserModal />
                )
            }


        </>
    )
}
