import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import api from "../../shared/api/Axios";
import apiAuthLXP from "../../shared/api/AxiosAuthLXP";
import { SecurityEndpoints } from "../../shared/api/services/ConfigApi";
import { Skeleton } from "../../shared/components/Skeleton";
import { useAuthContext } from "../context/AuthContext";
import { useAuth } from "../hooks/useAuth";
import { useInfoLocalStorage } from "../hooks/useInfoLocalStorage";

export interface LoginResponse {
  accessToken: string
  refreshToken: string
}

export interface LoginCodeResponse {
  success: boolean;
  data: {
    user_data: {
      name: string;
      surname1: string;
      surname2: string | null;
      email: string;
      phone: string;
    }
  }
}

export interface RegisterNewUserRequest {
  externalId: string
}

const isLocalhost = window.location.hostname === "localhost";


export function ExternalLoginPage() {
  const queryClient = useQueryClient();
  const { login } = useAuth();
  const { isAuthLoading, setIsAuthLoading, isAuthenticated } = useAuthContext();
  const [searchParams] = useSearchParams();
  const externalId = searchParams.get("key_id") as string;
  const code = searchParams.get("code") as string;
  const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT as string;
  const navigate = useNavigate();
  const { setExternalUserIdLocalStorage, clearAllLocalStorage } = useInfoLocalStorage();

  const loginUser = async (id: string): Promise<LoginResponse> => {
    const { data } = await api.post<LoginResponse>(SecurityEndpoints.externalLogin(id));
    return data;
  };

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      await queryClient.cancelQueries();
      await login(data.accessToken, data.refreshToken);
      await queryClient.fetchQuery({ queryKey: ['me'] });
      setIsAuthLoading(false);
    },
    onError: () => {
      if (!isLocalhost) {
        clearAllLocalStorage();
        //TODO: logout
        if (!isAuthenticated) {

          const userData = data?.data.user_data;
          navigate(ROUTES.LOGINERROR, {
            replace: true,
            state: {
              externalId,
              name: userData?.name,
              mail: userData?.email,
              surname1: userData?.surname1,
              surname2: userData?.surname2,
              phone: userData?.phone
            }
          });
        }
      }
    }
  });

  const { data, refetch: refecthLoginCode, isLoading: isLoadingQuery } = useQuery<LoginCodeResponse>({
    queryFn: async () => {
      const { data } = await apiAuthLXP.post<LoginCodeResponse>(SecurityEndpoints.LoginCodeAuth(code));
      return data;
    },
    queryKey: ["loginCode", code],
    enabled: false,
    retry: false
  });

  const checkAndLogin = async () => {

    if (isLocalhost) {
      loginMutation.mutate(externalId);
    }
    else {
      const response = await refecthLoginCode();
      if (response.data?.success) {
        loginMutation.mutate(externalId);
      }
      else {
        clearAllLocalStorage();
        window.location.href = redirectUrl;
      }
    }

  };

  useEffect(() => {
    if (!externalId) {
      window.location.href = redirectUrl;
    } else {
      checkAndLogin();
    }
  }, [externalId]);

  return (
    <div className="size-full ">
      <div className="flex flex-col items-center justify-center p-6 size-full gap-5 md:flex md:flex-row md:justify-evenly md:items-center ">

        {!isAuthLoading && (
          <div className="max-w-lg size-full">
            {Array(3)
              .fill(0)
              .map((_, i) => <Skeleton key={i} />)}
          </div>
        )
        }
      </div>
    </div>
  );
}