
import InputField from '../../../../shared/components/InputField'
import { useOrganizationSettingsContext } from '../../context/OrganizationSettingsContext'
import Button, { ButtonSize } from '../../../../shared/components/Button'
import { Toggle } from '../../../../shared/components/Toggle'
import { DropZone } from '../../../../shared/components/DropZone'
import api from '../../../../shared/api/Axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi'
import { ShowCustomToast } from '../../../../shared/components/ShowCustomToast'
import { useEffect, useState } from 'react'
import { GeneralSettingType } from '../../types/OrganizationSettingType'
import { useForm } from '../../../../shared/hooks/useForm'
import { Skeleton } from '../../../../shared/components/Skeleton'
import { ModalTwoOptions } from '../../../../shared/components/ModalTwoOptions'

export const GeneralSettingsOrganization = () => {
    const { formState: generalFormState, onInputChange: onGeneralInputChange, setFormState: setGeneralFormState } = useForm<GeneralSettingType>(
        {} as GeneralSettingType,
    )
    const [isModalOpen, setModalToggle] = useState(false);

    const [logoPreview, setLogoPreview] = useState<string | null>(generalFormState.logoInBase64 || null)
    const acceptedFileTypeTemplate = {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "image/gif": [".gif"],
        "image/webp": [".webp"]
    }

    const fetchGeneralSettings = async (): Promise<GeneralSettingType> => {
        const response = await api.get(SettingsEndpoints.GetOrganizationGeneral())
        return response.data
    }

    const { data: generalSettings, isLoading } = useQuery<GeneralSettingType>({
        queryKey: ['generalSettingsOrganization'],
        queryFn: fetchGeneralSettings
    })


    useEffect(() => {
        if (generalSettings) {
            setGeneralFormState(generalSettings)
        }
    }, [generalSettings])

    const updateGeneralMutation = useMutation({
        mutationFn: async () => {
            return await api.put(SettingsEndpoints.UpdateGeneral(), generalFormState)
        },
        onSuccess: () => {
            ShowCustomToast({
                title: "Guardado correctamente",
                message: "Los cambios se han guardado correctamente",
                icon: "success",
            })
        },
        onError: (error) => {
            ShowCustomToast({
                title: "Error al guardar",
                message: "Ha ocurrido un error al guardar los cambios",
                icon: "error",
            })
            console.error("Error updating general settings:", error)
        },
    })


    // Manejar la carga de un nuevo logo
    const onFileLogoChange = async (files: File[]) => {
        if (files.length === 0) return;

        const file = files[0]; // Solo tomamos el primer archivo
        const reader = new FileReader();

        reader.readAsDataURL(file); // Convierte el archivo a Base64
        reader.onloadend = () => {
            if (reader.result) {
                onGeneralInputChange("logoInBase64", reader.result.toString());
            }
        };
    };
    // Eliminar el logo actual
    const onRemoveAllFiles = () => {
        setLogoPreview(null)
        onGeneralInputChange("logoInBase64", null) // Limpia el campo en el estado
    }

    return (
        <>
            {isLoading ? (
                <div className="max-w-lg size-full">
                    {Array(3)
                        .fill(0)
                        .map((_, i) => <Skeleton key={i} />)}
                </div>
            )
                :
                <div className='flex flex-col gap-4 justify-between size-full'>

                    <div className='flex flex-col gap-4'>
                        <section className='flex flex-col '>
                            <p className='button16 text-NeutralNeutral1'>Nombre organización</p>
                            <InputField
                                type='text'
                                placeholder='Nombre organización'
                                name='name'
                                value={generalFormState.name}
                                onChange={(e) => onGeneralInputChange(
                                    e.target.name,
                                    e.target.value,
                                )}
                                withBorder={true}
                            />

                        </section>
                        <section className='flex flex-col gap-2'>
                            <p className='button16 text-NeutralNeutral1'>Dirección fiscal completa</p>
                            <InputField
                                type='text'
                                placeholder='Dirección fiscal completa'
                                name='address'
                                value={generalFormState.address}
                                onChange={(e) => onGeneralInputChange(
                                    e.target.name,
                                    e.target.value,
                                )}
                                withBorder={true}
                            />

                        </section>
                        <section className='flex flex-col gap-2'>
                            <p className='button16 text-NeutralNeutral1'>Logo</p>
                            <div className='flex  gap-2 justify-between items-center w-full h-fit'>
                                <img
                                    src={`data:image/svg+xml;base64, ${generalFormState.logoInBase64}`}
                                    className="rounded-lg border-2  border-NeutralNeutral5"
                                    alt="Logo de la organización"
                                />
                                <div className='size-fit'>
                                    <DropZone
                                        files={[]}
                                        onFilesChange={() => { }}
                                        acceptedFileTypes={acceptedFileTypeTemplate}
                                        onRemoveAllFiles={() => { }}
                                        hasIcon={false}
                                    />
                                    {/* <Button
                            text='Subir nuevo'
                            size={ButtonSize.medium}
                            onClick={() => { }} /> */}
                                </div>
                            </div>
                            <p className='bodys text-NeutralNeutral2'>Maximum size: 400 x 400px, png or jpeg</p>

                        </section>
                        <section className='flex flex-col gap-2'>
                            <p className='button16 text-NeutralNeutral1'>Dominio</p>
                            <p className='bodys text-NeutralNeutral2'>El dominio se obtiene del correo del propietario que ha creado la organización, cambiando el correo cambia el dominio.</p>

                            <InputField
                                type='text'
                                placeholder='educasign.ai'
                                name='domain'
                                value={generalFormState.domain}
                                onChange={(e) => onGeneralInputChange(
                                    e.target.name,
                                    e.target.value,
                                )}
                                withBorder={true}
                            />
                        </section>
                        <section className='flex flex-col gap-2'>
                            <p className='button16 text-NeutralNeutral1'>Privacidad</p>
                            <p className='bodys text-NeutralNeutral2'>Limitaciones parra acceder a la organización</p>

                            <Toggle
                                isChecked={generalFormState.hasLimitAccess}
                                onChange={(e) => onGeneralInputChange(
                                    "hasLimitAccess",
                                    !generalFormState.hasLimitAccess,
                                )}
                                label={`Solo usuarios con email `}
                            />
                        </section>
                    </div>


                    <div className="flex justify-center p-4">
                        <Button
                            onClick={() => setModalToggle(true)}
                            text='Guardar cambios'
                            size={ButtonSize.medium}
                            hasBg={true} />
                    </div>
                    {
                        isModalOpen &&
                        <ModalTwoOptions
                            title="¿Estas seguro?"
                            isOpen={isModalOpen}
                            onClose={() => setModalToggle(false)}
                            onConfirm={() => updateGeneralMutation.mutateAsync()}
                            cancelText="Cancelar"
                            confirmText="Confirmar"
                        />
                    }
                </div>

            }

        </>
    )
}
