import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchDocuments } from "../../documents/hooks/fetchDocuments";
import { ROUTES } from "../../router/routes";
import { getInitialRoute } from "../../router/utils/GetInitialRoute";
import api from "../../shared/api/Axios";
import { apiLogOutAuthLXP } from "../../shared/api/AxiosAuthLXP";
import { SecurityEndpoints } from "../../shared/api/services/ConfigApi";
import { PAGE_SIZE } from "../../shared/constants/DocumentConstants";
import { useAuthContext } from "../context/AuthContext";
import { LoginResponse } from "../pages/ExternalLoginPage";
import { GetMeRequest, Organization } from "../types/GetMeRequest";
import { LocalStorageKeys } from "../types/LocalStorageKeys";
import { useInfoLocalStorage } from "./useInfoLocalStorage";
import { usePermissions } from "./usePermission";
import { Permission } from "../types/Permissions";

const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT as string;
const pageSize = process.env.REACT_APP_PAGE_SIZE;

export const useAuth = () => {

    const { isAuthenticated, setUserInfoLocal, setUserOrganizationLogged, setExternalUserId, setMailUser, setNameUser, setOrganizations, setIsAuthenticated, selectedOrg, setSelectedOrg, externalUserId } = useAuthContext()
    const { getExternalUserIdLocalStorage, clearAllLocalStorage, getOrganizationsLocalStorage, setOrganizationsLocalStorage, setExternalUserIdLocalStorage, setMailUserLocalStorage, setUserOrganizationLoggedLocalStorage, setNameUserLocalStorage, getUserOrganizationLoggedLocalStorage, getMailUserLocalStorage, getNameUserLocalStorage } = useInfoLocalStorage()

    const queryClient = useQueryClient();

    const navigate = useNavigate()
    // console.log("Paginacion: ", pageSize)
    const logout = () => {
        logOutAuthLXP();
        Cookies.remove(LocalStorageKeys.accessToken)
        Cookies.remove(LocalStorageKeys.refreshToken)
        clearAllLocalStorage()
        setIsAuthenticated(false)
        window.location.href = redirectUrl;
    }

    const { refetch: logOutAuthLXP } = useQuery<null>({
        queryFn: async () => {
            const { data } = await apiLogOutAuthLXP.post<null>(SecurityEndpoints.LogOutAuth(externalUserId));
            return null;
        },
        queryKey: [],
        enabled: false,
        retry: false
    });

    const loginSetCookies = async (accessToken: string, refreshToken: string) => {
        Cookies.remove(LocalStorageKeys.accessToken)
        Cookies.remove(LocalStorageKeys.refreshToken)
        clearAllLocalStorage()
        Cookies.set(LocalStorageKeys.accessToken, accessToken, { expires: 1 / 24 }) // 1 hora
        //expires: 15 / (24 * 60 * 60) 15 segs
        Cookies.set(LocalStorageKeys.refreshToken, refreshToken, { expires: 7 }) // 7 días
        try {
            setIsAuthenticated(true)
            await getUserQuery.refetch()
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error)
            logout()
        }
    }


    const getUserQuery = useQuery<GetMeRequest>({
        queryKey: ['getMe'],
        queryFn: async () => {
            const { data } = await api.get<GetMeRequest>(SecurityEndpoints.getMe())
            setOrganizationsLocalStorage(data.organizations)
            setOrganizations(data.organizations)
            setUserOrganizationLoggedLocalStorage(data.userOrganizationLogged)
            setUserOrganizationLogged(data.userOrganizationLogged)
            setNameUserLocalStorage(data.name)
            setNameUser(data.name)
            setMailUserLocalStorage(data.mail)
            setMailUser(data.mail)
            setExternalUserIdLocalStorage(data.externalUserId)
            setExternalUserId(data.externalUserId)
            const org = data.organizations.find(org => org.userOrganizationId === data.userOrganizationLogged);
            if (org)
                setSelectedOrg(org);
            return data;
        },
        enabled: false,
    })


    const changeOrganizationMutation = useMutation<LoginResponse, Error, { orgId: string }>({
        mutationFn: async ({ orgId }) => {
            try {
                const { data } = await api.post<LoginResponse>(
                    `${SecurityEndpoints.changeOrganization()}?organizationId=${orgId}`,
                )
                return data
            } catch (error) {
                console.error("Error in mutation function:", error)
                throw error
            }
        },
        onSuccess: (data, variables) => {
            loginSetCookies(data.accessToken, data.refreshToken)
            // Invalidar y refrescar la caché de getMe
            queryClient.invalidateQueries({ queryKey: ['getMe'] });
        }
    })
    const { refetch } = useFetchDocuments(1, 1, 1, 1, 1, PAGE_SIZE, null)

    const handleOrganizationSelect = async (org: Organization) => {
        if (org.id === selectedOrg?.id) return
        try {
            await changeOrganizationMutation.mutateAsync({ orgId: org.id })
            setSelectedOrg(org)

            setUserOrganizationLogged(org.userOrganizationId)
            //Se ha optado a temp, porque selectedOrg no se actualiza al momento y coge el anterior
            const tempHasAccess = (permission: Permission) => {
                return org.roleYPermissions?.permissions?.some(p => p.name === permission);
            };
            const route = getInitialRoute(tempHasAccess);

            if (route === ROUTES.VISOR_DOCUMENTOS) {
                refetch();
            } else if (route === ROUTES.ALL_TEMPLATES) {
                // Request templates
            }
            navigate(route);
        } catch (error) {
            console.error("Error changing organization:", error)
        }
    }



    //Si ya estan las cookies y storage local, se cargan los datos
    useEffect(() => {
        const accessToken = Cookies.get(LocalStorageKeys.accessToken)
        const refreshToken = Cookies.get(LocalStorageKeys.refreshToken)
        if (accessToken && refreshToken) {
            const storedOrganizations = getOrganizationsLocalStorage()
            const storedExternalUserId = getExternalUserIdLocalStorage()
            const storedMailUser = getMailUserLocalStorage()
            const storedUserOrganizationLogged = getUserOrganizationLoggedLocalStorage()
            const storedNameUser = getNameUserLocalStorage()
            if (
                storedOrganizations &&
                storedExternalUserId &&

                storedUserOrganizationLogged &&
                storedNameUser
            ) {

                setOrganizations(storedOrganizations)
                setExternalUserId(storedExternalUserId)
                setMailUser(storedMailUser)
                setUserOrganizationLogged(storedUserOrganizationLogged)
                setNameUser(storedNameUser)
                setIsAuthenticated(true)


                const org = (storedOrganizations as Organization[]).find(org => org.userOrganizationId === storedUserOrganizationLogged);
                if (org)
                    setSelectedOrg(org);


            } else {
                getUserQuery.refetch()
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [])



    return { login: loginSetCookies, logout, handleOrganizationSelect }
}