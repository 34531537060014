import { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
type NavBarContextType = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    activeButtonNav: number;
    setActiveButtonNav: (activeButtonNav: number) => void;
}

export const NavBarContext = createContext<NavBarContextType | undefined>(undefined);

export const NavBarProvider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeButtonNav, setActiveButtonNav] = useState(1);
    return (
        <NavBarContext.Provider value={{
            isOpen,
            setIsOpen,
            activeButtonNav,
            setActiveButtonNav
        }}>
            <Outlet />
        </NavBarContext.Provider>
    );
}

export const useNavBarContext = () => {
    const context = useContext(NavBarContext);
    if (!context) {
        throw new Error("useNavBarContext must be used within a NavBarProvider");
    }
    return context;
};