import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../auth/context/AuthContext';
import { useAuth } from '../../../auth/hooks/useAuth';
import { usePermissions } from '../../../auth/hooks/usePermission';
import { ROUTES } from '../../../router/routes';
import CheckCircle from '../../Icons/CheckCircle';
import CloseIcon from '../../Icons/CloseIcon';
import { OrganizationIcon } from '../../Icons/OrganizationIcon';
import SettingsIcon from '../../Icons/SettingIcon';
import { useNavBarContext } from './context/NavBarContext';
import { navItems } from './types/NavItems';
import { Permissions } from '../../../auth/types/Permissions';



export const MobileSlideBar = () => {
    const { isOpen, setIsOpen, setActiveButtonNav } = useNavBarContext();
    const { hasAccess } = usePermissions()
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (isOpen) {
            setIsVisible(true)
        } else {
            const timer = setTimeout(() => setIsVisible(false), 300)
            return () => clearTimeout(timer)
        }
    }, [isOpen])

    if (!isVisible) return null

    return (
        <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0  pointer-events-none'}`} onClick={() => setIsOpen(false)}>
            <div
                className={`fixed top-0 left-0 bottom-0 w-80 bg-white shadow-lg transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    } flex flex-col h-full`}
                onClick={(e) => e.stopPropagation()}
            >
                <MobileMenuHeader />
                <div className="flex-grow flex flex-col overflow-y-auto">
                    <div>
                        <MobileMenu />
                        <MobileMenuOrganizations />
                    </div>
                    <div className="mt-auto">

                        <div className="flex flex-col gap-2 py-2 px-4 justify-center size-full  items-start ">
                            {
                                hasAccess(Permissions.SettingsRead) && <Settings />
                            }
                            <MobileMenuHelp />
                            <CloseSesion />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const MobileMenuHeader = () => {
    const { isOpen, setIsOpen } = useNavBarContext();
    return (
        <div className="flex justify-end items-center p-4 ">
            <button onClick={() => setIsOpen(false)}>
                <CloseIcon size={35} />
            </button>
        </div>
    )
}


const MobileMenu = () => {
    const { isOpen, setIsOpen, setActiveButtonNav, activeButtonNav } = useNavBarContext();
    return (

        <div className=' flex flex-col gap-4 '>
            <p className='bodymbold text-NeutralNeutral2 px-4'>
                Menu
            </p>

            <nav className="flex flex-col gap-3 px-4">
                {navItems.map((item, index) => (
                    <Link to={Array.isArray(item.route) ? item.route[0] : item.route}>
                        <button
                            key={index}
                            className="flex gap-5 items-center size-full text-left hover:bg-gray-100 rounded"
                            onClick={() => {
                                setActiveButtonNav(item.id)
                                setIsOpen(false)
                            }}
                        >
                            <item.icon />
                            <p className={` ${activeButtonNav === item.id ? " bodylbold text-NeutralNeutral1" : "bodyl text-NeutralNeutral2"}`}>{item.label}</p>
                        </button>
                    </Link>

                ))}
            </nav>
        </div>
    )
}

const MobileMenuOrganizations = () => {
    const { handleOrganizationSelect, logout } = useAuth()
    const { organizations, selectedOrg } = useAuthContext()
    const { setIsOpen } = useNavBarContext()
    return (
        <div className="p-4 ">
            <p className='bodymbold text-NeutralNeutral2 mb-4 '>
                Organizaciones
            </p>
            {organizations.map((org) => (
                <button
                    key={org.id}
                    onClick={() => {
                        handleOrganizationSelect(org)
                        setIsOpen(false)
                    }}
                    className="flex items-center w-full p-2 gap-1 text-left hover:bg-gray-100 rounded border-b last:border-b-0"
                >
                    <div className='w-[15%]'>
                        {org.urlImg ? (
                            <img src={org.urlImg} alt="" className="w-5 h-5 mr-3 rounded" />
                        ) : (
                            <div className="flex items-center justify-center p-1 mr-3  bg-gray-100 rounded" >

                                <OrganizationIcon size={20} color="#101216" />
                            </div>
                        )}
                    </div>

                    <p className={`w-[80%] bodylbold ${selectedOrg?.id === org.id ? " text-NeutralNeutral1" : " text-NeutralNeutral2"}`} >{org.name}</p>
                    <div className='shrink-0 w-[10%]'>

                        {selectedOrg?.id === org.id && <CheckCircle size={20} />}
                    </div>
                </button>
            ))}
        </div>
    )
}


const MobileMenuHelp: React.FC = () => (
    <div className=''>
        <Link to={ROUTES.FAQS} className="flex items-center justify-center w-full  bodyl hover:bg-gray-100" role="menuitem">Ayuda</Link>
    </div>
)

const CloseSesion = () => {
    const { logout } = useAuth()
    return (
        <button className="flex items-center hover:bg-red-100 rounded"
            onClick={() => {
                logout()
            }}>
            <p className="text-red-500 bodyl" >
                Cerrar sesión</p>
        </button>
    )
}

const Settings = () => {
    return (
        <div>
            <Link to={ROUTES.SETTINGS} className="flex items-center justify-center w-full   bodyl hover:bg-gray-100" role="menuitem">
                <SettingsIcon size={20} />
                <p className="ml-2 text-NeutralNeutral1">Configuración</p>
            </Link>
        </div>
    )
}