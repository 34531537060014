import { useEffect } from 'react';
import { PDFViewer } from '../../../shared/components/PdfViewer';
import { usePagination } from '../../../shared/hooks/usePagination';
import { ToPdfFromString } from '../../../shared/utils/ToPdfFromString';

export interface SignaturePreviewDocumentViewerProps {
    currentDocumentFile: string;
}

export const SignaturePreviewDocumentViewer = ({ currentDocumentFile }: SignaturePreviewDocumentViewerProps) => {
    const { currentPage, numPages, onDocumentLoadSuccess, onPageChange, setCurrentPage } = usePagination();

    useEffect(() => {
        // Restablecer la página a 1 cuando el archivo cambie
        setCurrentPage(1);
    }, [currentDocumentFile, setCurrentPage]);
    return (
        <div className="flex-1 size-full justify-center items-center overflow-hidden  rounded-lg">
            {currentDocumentFile && <PDFViewer
                file={ToPdfFromString(currentDocumentFile)}
                withZoom={true}
                withDragging={true}
                withDownload={true}
                withStartYFinalPagination={false}
                currentPage={currentPage}
                numPages={numPages}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                onPageChange={onPageChange}
            />}
        </div>

    )
}
