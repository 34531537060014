import { Outlet } from "react-router-dom"
import { MobileMenuButton } from "../components/nav/MobileMenuButton"
import { MobileSlideBar } from "../components/nav/MobileSlideBar"
import { NavBar } from "../components/nav/NavBar"
import { UserAvatar } from "../components/nav/UserAvatar"
import { globals } from "../constants/global"

export const EducaSignLayout = () => {
  return (
    <div className='flex flex-col bg-NeutralNeutral6  h-lvh'>
      <nav style={{ height: globals.ALTURA_TOPNAV }} className="flex justify-between items-center p-4  bg-white">
        <MobileMenuButton />
        <NavBar />
        <UserAvatar />
        <MobileSlideBar />
      </nav>
      <Outlet />
    </div>
  )
}

