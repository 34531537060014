import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router/routes';
import Button, { ButtonSize } from '../../../shared/components/Button';
import InputField from '../../../shared/components/InputField';
import { useFileTemplate } from '../../hooks/useFileTemplate';
import { useState } from 'react';
import { ShowCustomToast } from '../../../shared/components/ShowCustomToast';

export interface ModalCreateTemplateProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
}

export const ModalCreateTemplate = ({ isOpen, onClose }: ModalCreateTemplateProps) => {
    // const { nameDocument, setNameDocument, navigateWithNameDocument } = useFileTemplate();

    const navigate = useNavigate();
    const [nameDocument, setNameDocument] = useState("");
    const navigateWithNameDocument = () => {
        if (nameDocument.length === 0) {
            ShowCustomToast({
                title: "Error",
                message: "El nombre del documento no puede estar vacío",
                icon: "error",
            })
            return;
        }
        if (nameDocument.trim()) {
            navigate(`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`);
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center "
        >
            <div className="bg-white  p-6 rounded-lg w-96" onClick={(e) => e.stopPropagation()}>
                <h2 className="h5 mb-4">Crear plantilla

                </h2>
                <div className='flex flex-col gap-4'>
                    <InputField placeholder='Nombre de plantilla' type='text' value={nameDocument} onChange={(e) => setNameDocument(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault()
                                navigateWithNameDocument()
                            }
                        }} />
                </div>
                <div className="flex justify-end mt-6 gap-4">
                    <Button
                        size={ButtonSize.medium}
                        onClick={() => onClose(false)}
                        text='Cancelar'
                        hasBorder={true}
                    />
                    <Link to={`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`} >
                        <Button
                            size={ButtonSize.medium}
                            onClick={navigateWithNameDocument}
                            hasBg={true}
                            text='Crear plantilla'
                        />
                    </Link>

                </div>

            </div>
        </div>
    )
}
