import { error } from "console";
const TEMPLATES = "/templates";
const CREATE_TEMPLATE = `${TEMPLATES}/create`;

export const ROUTES = {
  INICIO: "/",
  LOGIN: "/login",
  LOADING: "/load",
  EXTERNALLOGIN: "/ExternalLogin",
  LOGINERROR: "/login-error",
  VALIDACION: "/validation",
  VISOR_DOCUMENTOS: "/document-viewer",
  SIGNATURE_PREVIEW: "/document-viewer/signature-preview",
  ESTADISTICAS: "/statistics",
  FAQS: "/Faqs",
  ALL_TEMPLATES: TEMPLATES,
  CREATE_DOCUMENT_TEMPLATE: CREATE_TEMPLATE,
  PREVIEW_DOCUMENT_TEMPLATE: `${CREATE_TEMPLATE}/preview`,
  DEVELOPERS: "/developers",
  YOURDATA_SETTINGS: "/settings/yourdata",
  ORGANIZATIONS_SETTINGS: "/settings/organizations",
  GROUP_SETTINGS: "/settings/organizations/group",
  ADD_GROUP_SETTINGS: "/settings/organizations/group/add",
  ADD_MEMBER_SETTINGS: "/settings/members/add",
  CERTIFICATIONS_SETTINGS: "/settings/certifications",
  NOTIFICATIONS_SETTINGS: "/settings/notifications",
  SETTINGS: "/settings",
};
