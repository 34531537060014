import { createContext, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useForm } from '../../../shared/hooks/useForm'
import { GeneralSettingType, GroupsSettingType, MembersSettingType, OrganizationSettingType } from '../types/OrganizationSettingType'

type OrganizationSettingsContextType = {
  viendo: string
  setViendo: (viendo: string) => void
  searchMembers: string,
  setSearchMembers: (search: string) => void
}

export const filters = ['general', 'miembros', 'grupos', 'alianzas']


export const OrganizationSettingsContext = createContext<OrganizationSettingsContextType | undefined>(undefined)

export const OrganizationSettingsProvider = () => {
  const [viendo, setViendo] = useState<typeof filters[number]>('general')

  const [searchMembers, setSearchMembers] = useState<string>('')

  return (
    <OrganizationSettingsContext.Provider
      value={{
        viendo,
        setViendo,
        searchMembers,
        setSearchMembers
      }}>
      <Outlet />
    </OrganizationSettingsContext.Provider>
  )
}

export const useOrganizationSettingsContext = () => {
  const context = useContext(OrganizationSettingsContext)
  if (!context) {
    throw new Error('useOrganizationSettings must be used within a OrganizationSettingsProvider')
  }
  return context
}
