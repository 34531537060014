import { Route, useLocation } from 'react-router-dom'
import { useAuthContext } from '../../auth/context/AuthContext'
import { ROUTES } from '../../router/routes'
import { CertificationsPages } from '../certifications/page/CertificationsPages'
import { SettingsProvider } from '../context/SettingsContext'
import { ConfigurationLayout } from '../layouts/ConfigurationLayout'
import { SettingsLayout } from '../layouts/SettingsLayout'
import { NotificationsPage } from '../notifications/page/NotificationsPage'
import { OrganizationSettingsProvider } from '../organizations/context/OrganizationSettingsContext'
import { NavOrganizationSettingLayout } from '../organizations/layouts/NavOrganizationSettingLayout'
import { OrganizationSettingPage } from '../organizations/pages/OrganizationSettingPage'
import { AddGroupSettingOrganization } from '../organizations/views/Grupos/AddGroupSettingOrganization'
import { GrupoSettingOrganization } from '../organizations/views/Grupos/GrupoSettingOrganization'
import { NewMemberSettingsOrganizationPage } from '../organizations/views/Miembros/NewMemberSettingsOrganizationPage'
import { YourDataPage } from '../users/page/YourDataPage'

export const settingsRoute = () => {
  return (
    <>
      <Route element={<ConfigurationLayout />}>
        <Route element={<SettingsProvider />}>
          <Route element={<OrganizationSettingsProvider />}>
            {/* Página principal de ajustes con Layout */}
            <Route path={ROUTES.SETTINGS} element={<SettingsLayout />}>
              {/* Contenido dinámico dentro del Layout */}
              <Route element={<NavOrganizationSettingLayout />}>
                <Route path={ROUTES.ORGANIZATIONS_SETTINGS} element={<OrganizationSettingPage />} />
              </Route>


              <Route element={<GrupoSettingOrganization />} path={`${ROUTES.GROUP_SETTINGS}/:groupId`} />
              <Route element={<AddGroupSettingOrganization />} path={`${ROUTES.ADD_GROUP_SETTINGS}`} />

              <Route path={ROUTES.CERTIFICATIONS_SETTINGS} element={<CertificationsPages />} />
              <Route path={ROUTES.NOTIFICATIONS_SETTINGS} element={<NotificationsPage />} />
              <Route path={ROUTES.YOURDATA_SETTINGS} element={<YourDataPage />} />

              <Route element={<ConfigurationLayout />}>
                <Route element={<NewMemberSettingsOrganizationPage />} path={`${ROUTES.ADD_MEMBER_SETTINGS}`} />
              </Route>
            </Route>

          </Route>
        </Route>

      </Route>
    </>
  )
}
