interface UserIconProps {
    className?: string
    size?: number
    color?: string
}

export default function UserIcon({ className = '', size = 18, color = '#94A3B8' }: UserIconProps) {
    return (
        <svg
            width={size}
            height={Math.ceil(size * 22 / 18)} // Mantiene la proporción original
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.75 5C12.75 7.07107 11.071 8.75 8.99996 8.75C6.9289 8.75 5.24996 7.07107 5.24996 5C5.24996 2.92893 6.9289 1.25 8.99996 1.25C11.071 1.25 12.75 2.92893 12.75 5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5011 19.1182C1.5714 15.0369 4.90184 11.75 8.99996 11.75C13.0982 11.75 16.4287 15.0371 16.4988 19.1185C14.216 20.166 11.6764 20.75 9.00028 20.75C6.32396 20.75 3.78406 20.1659 1.5011 19.1182Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
} 