import React, { useCallback, useState } from 'react'
import DownloadIcon from '../Icons/DownloadIcon'
import { useDropzone, Accept } from 'react-dropzone'
import { checkImageAboveDimensions, checkImageBelowDimensions } from '../utils/checkImageDimensions'

export interface MaxDimensions {
    Width: number,
    Height: number,
}

export interface MinDimensions {
    Width: number,
    Height: number
}

export interface DropZoneProps {
    files: File[],
    onFilesChange: (files: File[]) => void,
    acceptedFileTypes?: Accept,
    maxFiles?: number,
    maxDimensions?: MaxDimensions,
    minDimensions?: MinDimensions,
    maxSize?: number
    onRemoveAllFiles?: () => void
    hasIcon?: boolean
    tittle?: string
}
export const DropZone = ({ files, onFilesChange, acceptedFileTypes, maxFiles, maxDimensions, maxSize, minDimensions, onRemoveAllFiles, hasIcon = true, tittle }: DropZoneProps) => {
    const [error, setError] = useState<string | null>(null);

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (maxDimensions || minDimensions) {
                const validFiles = [];
                for (const file of acceptedFiles) {

                    if (maxDimensions) {
                        const isValidSize = await checkImageBelowDimensions(file, maxDimensions.Width, maxDimensions.Height);
                        if (isValidSize) {
                            validFiles.push(file);
                        } else {
                            setError('El tamaño de la imagen no puede mayor a ' + maxDimensions.Width + 'x' + maxDimensions.Height);
                            return;
                        }
                    }
                    else if (minDimensions) {
                        const isValidSize = await checkImageAboveDimensions(file, minDimensions.Width, minDimensions.Height);
                        if (isValidSize) {
                            validFiles.push(file);
                        } else {
                            setError('El tamaño de la imagen debe ser mayor a ' + minDimensions.Width + 'x' + minDimensions.Height);
                            return;
                        }
                    }

                }
                setError(null);
                acceptedFiles = validFiles;
            }
            onFilesChange(acceptedFiles)
        },
        [onFilesChange]
    );

    const removeAllFiles = useCallback(() => {
        onFilesChange([])
    }, [onFilesChange])


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        maxSize: maxSize != undefined ? maxSize : 10 * 1024 * 1024, // 10MB
        maxFiles: maxFiles !== undefined ? maxFiles : 1
    })

    return (
        <div
            {...getRootProps()}
            className={`
             ${hasIcon && "border-dashed border-2 border-NeutralNeutral3 rounded-lg"} 
            text-center cursor-pointer transition-colors size-full p-2
            hover:border-CorporatePrimary hover:bg-slate-50 
            ${isDragActive ? 'border-blue-500 bg-blue-50/80' : ''}
             ${files.length > 0 ? 'border-CorporatePrimary' : ''}
          `}
        >
            <input {...getInputProps()} />
            < div className={files.length > 0 ? 'hidden' : 'flex flex-col items-center justify-center gap-2 size-full'}>
                {hasIcon && <DownloadIcon className=' animate-bounce' />}
                <div className='bg-NeutralNeutral6 rounded-md py-2 px-4 text-center'>
                    <p className='button16 text-NeutralNeutral2'> {tittle ?? 'Subir fichero'}</p>
                </div>
            </div>

            <div className="size-full flex justify-center items-center">
                {error && <p className="text-red-500 mt-2">{error}</p>}

                {files.length > 0 && (
                    <>

                        <div className="">
                            {files.map((file, index) => (
                                <div
                                    key={index}
                                    className="border border-NeutralNeutral3 rounded-lg  items-center flex"
                                >
                                    <p className="text-sm text-NeutralNeutral2 text-center overflow-hidden text-ellipsis">{file.name}</p>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            removeAllFiles()
                                            if (onRemoveAllFiles)
                                                onRemoveAllFiles()
                                        }}
                                        className="ml-4 text-NeutralNeutral2 hover:bg-CorporateCorp04 rounded-full bg-NeutralNeutral5 mr-2 size-6"
                                    >
                                        <p>X</p>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
