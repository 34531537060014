import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../../../../shared/api/Axios'
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi'
import Button, { ButtonSize } from '../../../../shared/components/Button'
import InputField from '../../../../shared/components/InputField'
import { ShowCustomToast } from '../../../../shared/components/ShowCustomToast'
import { Toggle } from '../../../../shared/components/Toggle'
import { useOrganizationSettingsContext } from '../../context/OrganizationSettingsContext'
import { ModalTwoOptions } from '../../../../shared/components/ModalTwoOptions'

interface GroupDetailsResponse {
    groupId: string;
    name: string;
    users: UserGroupResponse[];
}

interface UserGroupResponse {
    userOrganizationId: string;
    name: string;
    mail: string;
    role: string;
    isInGroup: boolean;
}

interface UpdateGroupType {
    groupId: string;
    name: string;
    usersOrganizationsId: string[];
}


export const GrupoSettingOrganization = () => {
    const { groupId } = useParams();
    const [nameGroup, setNameGroup] = useState('');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    // State to track users with their toggle states
    const [users, setUsers] = useState<UserGroupResponse[]>([])
    const [isModalOpen, setModalToggle] = useState(false);

    const { data: groupDetails } = useQuery<GroupDetailsResponse>({
        queryKey: ['groupDetails', groupId],
        queryFn: async () => {
            const response = await api.get(SettingsEndpoints.GetGroup(groupId ?? ""))
            return response.data
        },
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    })

    // Update local state when groupDetails is fetched
    useEffect(() => {
        if (groupDetails && groupDetails.users !== users) {
            setUsers(groupDetails.users)
            setNameGroup(groupDetails.name)
        }
    }, [groupDetails])


    const handleToggleChange = (userId: string) => {
        setUsers((prevUsers) =>
            prevUsers.map((user) => (
                user.userOrganizationId === userId
                    ? { ...user, isInGroup: !user.isInGroup }
                    : user)),
        )
    }


    const udpateGroupAsync = async () => {
        const selectedUserIds = users.filter((user) => user.isInGroup).map((user) => user.userOrganizationId)

        const updateGroupRequest: UpdateGroupType = {
            groupId: groupId ?? "",
            name: nameGroup,
            usersOrganizationsId: selectedUserIds
        };
        await api.put(SettingsEndpoints.UpdateGroup(), updateGroupRequest)
    }

    const UpdateGroupMutation = useMutation({
        mutationKey: ['UpdateGroup'],
        mutationFn: udpateGroupAsync,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizationSetting"] })

            ShowCustomToast({
                title: "Guardado correctamente",
                message: "Los cambios se han guardado correctamente",
                icon: "success",
            })
            navigate(-1)
        },
        onError: (error) => {
            ShowCustomToast({
                title: "Error al guardar",
                message: "Ha ocurrido un error al guardar los cambios",
                icon: "error",
            })
            console.error("Error updating general settings:", error)
        },
    })



    return (
        <>
            <div className='flex flex-col gap-4 p-4 h-full'>
                <div className='flex flex-col gap-4'>
                    <p className='button16 text-NeutralNeutral1'>Nombre del grupo</p>
                    <InputField
                        type='text'
                        placeholder='Nombre del grupo'
                        value={nameGroup}
                        onChange={(e) => setNameGroup(e.target.value)}
                        withBorder={true}
                    />
                </div>
                <div className='flex flex-col gap-4'>
                    <p className='button16 text-NeutralNeutral1'>Seleccionar usuarios</p>
                    <div className='flex flex-col gap-2'>
                        {
                            users.map((user) => (
                                <div key={user.userOrganizationId} className='flex flex-row gap-2'>
                                    <Toggle
                                        isChecked={user.isInGroup}
                                        onChange={() => handleToggleChange(user.userOrganizationId)}
                                    />
                                    <p className='bodyl text-NeutralNeutral2'>{user.name} ({user.role})</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='mt-auto flex justify-end'>
                    <Button
                        text='Guardar'
                        onClick={() => setModalToggle(true)}
                        size={ButtonSize.medium}
                        hasBg={true}
                    />
                </div>
                {
                    isModalOpen &&
                    <ModalTwoOptions
                        title="¿Estas seguro?"
                        isOpen={isModalOpen}
                        onClose={() => setModalToggle(false)}
                        onConfirm={() => UpdateGroupMutation.mutateAsync()}
                        cancelText="Cancelar"
                        confirmText="Confirmar"
                    />
                }
            </div>
        </>
    )
}
