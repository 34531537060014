import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../assets/icons/logo.svg';
import { ROUTES } from '../../../router/routes';
import OrganizationSelector from './OrganizationSelector';
import { navItems } from './types/NavItems';
import { usePermissions } from '../../../auth/hooks/usePermission';
import { Permissions } from '../../../auth/types/Permissions';


export const NavBar = () => {
    const location = useLocation()
    const { hasAccess } = usePermissions()
    return (
        <div className="hidden md:flex md:w-full md:pl-3 md:items-center md:justify-between gap-4">
            <img src={logo} alt="Logo" className="hidden md:block size-14" />
            <div className="flex-none w-1/4 flex items-center justify-center h-full">
                <OrganizationSelector />
            </div>
            <div className="flex-grow flex justify-center">
                <ul className="flex gap-6">
                    {navItems
                        .filter((item) => {
                            if (item.route === ROUTES.ESTADISTICAS) {
                                return hasAccess(Permissions.StatisticsRead);
                            }
                            if (item.route === ROUTES.VISOR_DOCUMENTOS) {
                                return hasAccess(Permissions.DocumentsRead);
                            }
                            if (item.route === ROUTES.ALL_TEMPLATES) {
                                return hasAccess(Permissions.TemplatesRead);
                            }
                        }).map((item) => (
                            <li key={item.id}>

                                <Link
                                    to={Array.isArray(item.route) ? item.route[0] : item.route}
                                    className={`button16 ${location.pathname === item.route ||
                                        (item.route === ROUTES.VISOR_DOCUMENTOS && location.pathname.startsWith("/document-viewer"))
                                        ? "underline underline-offset-4 decoration-2 text-NeutralNeutral1"
                                        : "text-NeutralNeutral2"
                                        }`}
                                >
                                    {item.label}
                                </Link>

                            </li>
                        ))}
                </ul>
            </div>
            <div className="flex-none w-1/4">
                {/* Este div vacío ayuda a equilibrar el diseño */}
            </div>
        </div>
    )
}
