import React from 'react'
import { itemSetting } from '../types/allSettings'
import CheckCircle from '../../shared/Icons/CheckCircle'
import { ChevronRight } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSettingsContext } from '../context/SettingsContext'

export interface SettingItemProps {
    setting: itemSetting
    onClick?: () => void
}

export const SettingItem = ({ setting, onClick }: SettingItemProps) => {
    const navigate = useNavigate()
    const { setViendo } = useSettingsContext();
    const { pathname } = useLocation();

    const isSelected = pathname.startsWith(setting.route)


    return (
        <Link className='size-full' to={setting.route} onClick={() => {
            setViendo(setting.route)
            onClick && onClick()
        }}>

            <button className={`size-full flex justify-between items-center  px-6 hover:bg-NeutralNeutral6'}`}>

                <div className={`flex gap-2 items-center`}>
                    <setting.icon color={`${isSelected ? '#32bb78 ' : '#64758b'}`} />
                    <p className={`button16 ${isSelected ? 'text-NeutralNeutral1' : 'text-NeutralNeutral2'}`}>{setting.label}</p>
                </div>
                <div className='shrink-0 w-5 h-5'>
                    <ChevronRight size={24} />
                </div>
            </button>
        </Link>
    )
}
