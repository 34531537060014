'use client'
import type React from "react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { Size } from "../../Templates/types"
import { Pagination } from "./Pagination"
import { usePagination } from "../hooks/usePagination"
import Button, { ButtonSize } from "./Button"
import { CustomSlider } from "./Slider"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
import { DownloadIcon, ExternalLink } from 'lucide-react'

interface PDFViewerProps {
    file: File | null
    children?: React.ReactNode
    handlePageLoadSuccess?: (page: any) => void
    withZoom?: boolean,
    withDragging?: boolean
    withDownload?: boolean
    withStartYFinalPagination?: boolean
    currentPage: number
    numPages: number
    onDocumentLoadSuccess: (numPages: number) => void
    onPageChange: (newPage: number) => void
}

export const PDFViewer: React.FC<PDFViewerProps> = ({
    file,
    children,
    handlePageLoadSuccess,
    withZoom = false,
    withDragging = false,
    withDownload = false,
    withStartYFinalPagination = true,
    currentPage = 1,
    numPages = 1,
    onDocumentLoadSuccess,
    onPageChange
}) => {
    const [pageDimensions, setPageDimensions] = useState<Size>({ width: 0, height: 0 })
    const containerRef = useRef<HTMLDivElement>(null)
    const [scaledWidth, setScaledWidth] = useState(1)
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [isDragging, setIsDragging] = useState(false)
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
    const [zoomLevel, setZoomLevel] = useState(1)

    //Si no ponia useCallback, el componente se renderiza con cada renderizado lo que causa problemas
    const updateScale = useCallback(() => {
        if (containerRef.current) {
            const { clientWidth, clientHeight } = containerRef.current
            const { width: pdfWidth, height: pdfHeight } = pageDimensions

            // Escalar PDF manteniendo la relación de aspecto
            const scaleFactor = Math.min(clientWidth / pdfWidth, clientHeight / pdfHeight)
            setScaledWidth(Math.round(pdfWidth * scaleFactor * zoomLevel));
        }
    }, [pageDimensions, zoomLevel])

    useEffect(() => {
        updateScale()
        window.addEventListener("resize", updateScale)
        return () => window.removeEventListener("resize", updateScale)
    }, [pageDimensions, zoomLevel])

    if (!file) {
        return null
    }

    const onPageloaded = (page: any) => {

        if (handlePageLoadSuccess)
            handlePageLoadSuccess(page)

        setPageDimensions({ width: page.width, height: page.height })
    }
    const handleZoomChange = (value: number) => {
        setZoomLevel(value)
    }


    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true)
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y })
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - dragStart.x,
                y: e.clientY - dragStart.y,
            })
        }
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const downloadFile = (file: File) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    return (
        <div className="flex flex-col h-full bg-NeutralNeutral6 rounded-lg">
            <div ref={containerRef} className="flex-grow overflow-hidden  ">
                <div
                    onMouseDown={withDragging ? handleMouseDown : undefined}
                    onMouseMove={withDragging ? handleMouseMove : undefined}
                    onMouseUp={withDragging ? handleMouseUp : undefined}
                    onMouseLeave={withDragging ? handleMouseUp : undefined}
                    style={withDragging ? {
                        transform: `translate(${position.x}px, ${position.y}px)`,
                        cursor: isDragging ? "grabbing" : "grab",
                    } : {}}
                    className=" size-full  "
                >
                    <Document
                        file={file}
                        onLoadSuccess={(numPages) => onDocumentLoadSuccess(numPages.numPages)}
                        className="relative flex size-full justify-center items-center  "
                    >
                        <Page
                            pageNumber={currentPage}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            onRenderSuccess={onPageloaded}
                            width={scaledWidth}

                        >
                            {children}
                        </Page>
                    </Document>

                </div>

            </div>
            <hr className="border-NeutralNeutral5 border-1" />

            <div className="  justify-between bottom-2 flex items-center py-2 px-4 ">
                {withDownload
                    ? <div className=" md:flex-1 md:block">
                        <ExternalLink
                            className="text-CorporatePrimary hover:text-CorporateCorpDark hover:cursor-pointer"
                            onClick={() => window.open(window.URL.createObjectURL(file))}
                        // onClick={() => downloadFile(file)}
                        />
                    </div>
                    : <div className="hidden md:block flex-1 size-full" />}
                {/* <div className="hidden md:block flex-1 size-full" /> */}

                <div className="flex-1 size-full flex justify-center items-center ">
                    <Pagination
                        page={currentPage}
                        totalCount={numPages || 0}
                        pageSize={1}
                        onPageChange={onPageChange}
                        withStartYFinal={withStartYFinalPagination}
                    />
                </div>
                <div className="hidden md:block flex-1 size-full">
                    {withZoom && (
                        <div className="flex size-full justify-end items-center ">
                            <CustomSlider min={1} max={3} step={0.1} value={zoomLevel} onChange={handleZoomChange} orientation="horizontal" />
                        </div>
                    )}
                </div>
            </div>
        </div>


    )
}

