import React from 'react'
import Button, { ButtonSize } from './Button';
import InputField from './InputField';

interface ModalTwoOptionsProps {
    // Basic props
    title: string
    isOpen: boolean
    onClose: () => void

    // Button customization
    cancelText?: string
    confirmText?: string

    // Button actions
    onCancel?: () => void
    onConfirm?: () => void

    //Input
    placeholder?: string
    type?: "text" | "number" | "password"
    value?: string
    onChange?: (e: any) => void

}

export const ModalTwoOptions = ({
    title,
    isOpen,
    onClose,
    cancelText = "Cancelar",
    confirmText = "Aceptar",
    onCancel,
    onConfirm,
    placeholder,
    type,
    value,
    onChange
}: ModalTwoOptionsProps) => {


    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm()
        }
        onClose()
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        }
        onClose()
    }



    return (
        <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center "
            onClick={onClose}
        >
            <div className="bg-white  p-6 rounded-lg w-96" onClick={(e) => e.stopPropagation()}>
                <h2 className="h5 mb-4">
                    {title}
                </h2>
                {
                    placeholder && type && value && onChange &&
                    (
                        <InputField placeholder={placeholder} type={type} value={value} onChange={onChange} />
                    )
                }
                <div className="flex justify-end mt-6 gap-4">
                    <Button
                        size={ButtonSize.medium}
                        onClick={handleCancel}
                        text={cancelText}
                        hasBorder={true}
                    />
                    <Button
                        size={ButtonSize.medium}
                        onClick={handleConfirm}
                        hasBg={true}
                        text={confirmText}
                    />
                </div>
            </div>
        </div>
    )
}
