import React from 'react'

interface RadioButtonProps {
    label: string
    checked: boolean
    onChange: (checked: boolean) => void
    className?: string
}

export const RadioButton = ({ label, checked, onChange, className = '' }: RadioButtonProps) => {
    return (
        <div className={`flex items-center gap-2 ${className}`}>
            <div className="relative flex items-center justify-center size-5">
                <input
                    type="radio"
                    checked={checked}
                    onChange={() => onChange(!checked)}
                    className="appearance-none absolute inset-0 border-2 border-CorporatePrimary rounded-full checked:border-CorporatePrimary cursor-pointer focus:outline-none focus:ring-2 focus:ring-CorporatePrimary focus:ring-offset-2"
                />
                <div className={`size-2 rounded-full bg-CorporatePrimary transition-opacity duration-200
                                ${checked ? 'opacity-100' : 'opacity-0'}`}>
                </div>
            </div>
            <label className="bodyl text-NeutralNeutral2 cursor-pointer">{label}</label>
        </div>
    )
}
