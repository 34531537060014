import { useState } from "react";

export const useForm = <T extends Record<string, any>>(initialForm: T) => {
  const [formState, setFormState] = useState<T>(initialForm);
  const onInputChange = (field: any, value: any) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const onResetForm = () => {
    setFormState(initialForm);
  };

  return {
    ...formState,
    formState,
    onInputChange,
    onResetForm,
    setFormState,
  };
};
