import React from 'react'
import { toast } from 'react-toastify'
import CheckCircle from '../Icons/CheckCircle'
import 'react-toastify/dist/ReactToastify.css'
import ErrorIcon from '../Icons/ErrorIcon'

interface CustomToastProps {
  title: string
  message: string
  icon?: "success" | "error"
}

const iconComponents = {
  success: CheckCircle,
  error: ErrorIcon,
}

const colorTitle = {
  success: "text-CorporatePrimary",
  error: "text-red-600",
}

const CustomToast = ({ title, message, icon = "success" }: CustomToastProps) => {
  const IconComponent = iconComponents[icon]
  const ColorTitle = colorTitle[icon]
  return (
    <div className="flex items-center w-full max-w-3xl overflow-hidden">
      <IconComponent className="size-12" />
      <div className="px-4 py-2 flex-grow flex flex-col gap-2">
        <h3 className={`button16 ${ColorTitle} `}>{title}</h3>
        <p className="bodym text-NeutralNeutral2">{message}</p>
      </div>
    </div>
  )
}

export const ShowCustomToast = ({ title, message, icon = "success" }: CustomToastProps) => {
  toast(<CustomToast title={title} message={message} icon={icon} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    style: {
      zIndex: 99999,
    },
  });
};