import React, { useState, useEffect, useRef, useCallback } from 'react';

interface CustomSliderProps {
    min: number;
    max: number;
    step: number;
    value: number;
    orientation?: 'horizontal' | 'vertical';
    onChange: (value: number) => void;
}

export const CustomSlider: React.FC<CustomSliderProps> = ({ min, max, step, value, onChange, orientation = 'horizontal' }) => {
    const [isDragging, setIsDragging] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    const getPercentage = useCallback((currentValue: number) => {
        return ((currentValue - min) / (max - min)) * 100;
    }, [min, max]);

    const getValue = useCallback((percentage: number) => {
        const rawValue = (percentage * (max - min)) / 100 + min;
        const steppedValue = Math.round(rawValue / step) * step;
        return Math.max(min, Math.min(max, steppedValue));
    }, [min, max, step]);

    const handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsDragging(true);
        updateValue(event);
    };

    const updateValue = useCallback(
        (event: MouseEvent | React.MouseEvent) => {
            if (sliderRef.current) {
                const rect = sliderRef.current.getBoundingClientRect()
                let percentage
                if (orientation === "vertical") {
                    percentage = ((rect.bottom - event.clientY) / rect.height) * 100
                } else {
                    percentage = ((event.clientX - rect.left) / rect.width) * 100
                }
                const newValue = getValue(percentage)
                onChange(newValue)
            }
        },
        [getValue, onChange, orientation],
    )

    const handleMouseMove = useCallback((event: MouseEvent) => {
        if (isDragging) {
            updateValue(event);
        }
    }, [isDragging, updateValue]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    const sliderStyles = orientation === "vertical" ? "h-32 w-4" : "w-32 h-4"

    const fillStyles =
        orientation === "vertical"
            ? `w-full bg-CorporatePrimary absolute bottom-0 rounded-full`
            : `h-full bg-CorporatePrimary absolute left-0 rounded-full`

    const thumbStyles =
        orientation === "vertical"
            ? `left-1/2 transform -translate-x-1/2 -translate-y-1/2`
            : `top-1/2 transform -translate-y-1/2 -translate-x-1/2`

    return (
        <div
            ref={sliderRef}
            className={`relative bg-CorporatePrimary rounded-full cursor-pointer ${sliderStyles} `}
            onMouseDown={handleMouseDown}
        >
            <div
                className={fillStyles}
                style={
                    orientation === "vertical" ? { height: `${getPercentage(value)}%` } : { width: `${getPercentage(value)}%` }
                }
            />
            <div
                className={`absolute size-5 bg-white border-2 border-CorporateCorpDark rounded-full ${thumbStyles}`}
                style={
                    orientation === "vertical" ? { bottom: `${getPercentage(value)}%` } : { left: `${getPercentage(value)}%` }
                }
            />
        </div>
    )
};
