import { useMutation, useQuery } from '@tanstack/react-query'
import api from '../../../../shared/api/Axios'
import { SettingsEndpoints } from '../../../../shared/api/services/ConfigApi'
import InputField from '../../../../shared/components/InputField'
import { RadioButton } from '../../../../shared/components/RadioButton'
import { InfoToInviteUserToOrganization } from '../../types/InfoToInviteUserToOrganization'
import { useState } from 'react'
import Button, { ButtonSize } from '../../../../shared/components/Button'
import { InvitationUserToOrganization } from '../../types/InvitationUserToOrganization'

export const NewMemberSettingsOrganizationPage = () => {

    const { data: infoUser } = useQuery<InfoToInviteUserToOrganization>({
        queryKey: ['get'],
        queryFn: async () => {
            const response = await api.get(SettingsEndpoints.GetInfoToInviteUserToOrganization())
            return response.data
        },
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    })

    const inviteUser = useMutation({
        mutationFn: (invitation: InvitationUserToOrganization) => {
            return api.post(SettingsEndpoints.InviteUserToOrganization(), invitation)
        },
        onSuccess: () => {
        },
        onError: (error) => {
            console.error('Error invitacion:', error)
            // Handle error (e.g., show error message to user)
        },

    })

    // Agregar estado para roles seleccionados
    const [selectedRoles, setSelectedRoles] = useState<number[]>([])

    // Función para manejar la selección/deselección de roles
    const handleRoleToggle = (roleId: number) => {
        setSelectedRoles(prev => {
            if (prev.includes(roleId)) {
                return prev.filter(id => id !== roleId)
            } else {
                return [...prev, roleId]
            }
        })
    }

    const [mail, setMail] = useState('')
    return (
        <div className='flex flex-col size-full p-4 gap-4'>
            <section className='flex flex-col gap-2 w-full h-fit'>
                <h1 className='button16 text-NeutralNeutral1'>Email de usuario</h1>
                <InputField
                    placeholder='Email miembro'
                    type='text'
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                />
            </section>

            {/* <section className='flex flex-col gap-2 w-full h-fit'>
                <h1 className='button16 text-NeutralNeutral1'>Seleccionar grupo</h1>
                <InputField
                    placeholder='Email miembro'
                    type='text'
                    value=''
                    onChange={(e) => console.log(e.target.value)}
                />
            </section> */}

            <section className='flex flex-col gap-2 w-full h-fit'>
                <h1 className='button16 text-NeutralNeutral1'>Roles de usuario</h1>
                {

                    infoUser?.roles?.map((role) => {
                        return (
                            <section
                                key={role.id}
                                className='flex flex-col gap-2 w-full h-fit'>
                                <RadioButton
                                    label={role.name}
                                    checked={selectedRoles.includes(role.id)}
                                    onChange={() => handleRoleToggle(role.id)}
                                />
                            </section>
                        )
                    })
                }
            </section>

            <div className='mt-auto'>
                <Button
                    size={ButtonSize.medium}
                    text='Invitar usuario'
                    hasBg={true}
                    onClick={() => inviteUser.mutateAsync({ Mail: mail, RoleId: selectedRoles[0] })}
                />
            </div>

        </div>
    )
}
