import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { usePermissions } from '../../auth/hooks/usePermission'
import { Permissions } from '../../auth/types/Permissions'
import { ROUTES } from '../../router/routes'
import Button, { ButtonSize } from '../../shared/components/Button'
import { Pagination } from '../../shared/components/Pagination'
import { PAGE_SIZE } from '../../shared/constants/DocumentConstants'
import { useDocumentosContext } from '../context/DocumentosContext'
import { ListaDocumentos } from './ListaDocumentos'
export const VisorDocumentos = () => {
  const { setPageAll, pageAll, setPageCompleted, setPageInProgress, setPagePending, pageCompleted, pageInProgress, pagePending, totalCountAll, totalCountCompleted, totalCountPending, totalCountInProgress, viendo, aplicarFiltro, handleSelectAll, handleDocumentSelect, selectedDocuments, handleUnSelectAll, setPageCanceled } = useDocumentosContext()

  const handlePageAllChange = (page: number) => {
    handleUnSelectAll();
    setPageAll(page);
  };
  const handlePagePendingChange = (page: number) => {
    handleUnSelectAll();
    setPagePending(page);
  };
  const handlePageInProgressChange = (page: number) => {
    handleUnSelectAll();
    setPageInProgress(page);
  };
  const handlePageCompletedChange = (page: number) => {
    handleUnSelectAll();
    setPageCompleted(page);
  };

  const handlePageCanceledChange = (page: number) => {
    handleUnSelectAll();
    setPageCanceled(page);
  };

  const showSigningSection = selectedDocuments.length > 0 && (viendo === "pending" || viendo === "all")

  const { hasAccess } = usePermissions();

  return (
    <div
      className={`bg-slate-100 flex justify-center pb-6  ${showSigningSection && 'mb-10'}`}>
      <div
        className='flex flex-col justify-between h-full bg-white w-full px-6 py-3 min-w-md md:max-w-screen-lg  rounded-b-lg ' >

        <ListaDocumentos />
        <div>
          <div className='mx-auto'>
          </div>
          {!showSigningSection &&
            viendo === "all" ? (
            <div className=" flex justify-center">
              <Pagination
                page={pageAll}
                totalCount={totalCountAll}
                pageSize={PAGE_SIZE}
                onPageChange={handlePageAllChange}
              />
            </div>
          ) :
            viendo === "completed" ? (
              <div className=" flex justify-center">
                <Pagination
                  page={pageCompleted}
                  totalCount={totalCountCompleted}
                  pageSize={PAGE_SIZE}
                  onPageChange={handlePageCompletedChange}
                />
              </div>
            ) :
              viendo === "inProgress" ? (
                <div className=" flex justify-center">
                  <Pagination
                    page={pageInProgress}
                    totalCount={totalCountInProgress}
                    pageSize={PAGE_SIZE}
                    onPageChange={handlePageInProgressChange}
                  />
                </div>
              ) : !showSigningSection &&
                viendo === "pending" ? (
                <div className=" flex justify-center">
                  <Pagination
                    page={pagePending}
                    totalCount={totalCountPending}
                    pageSize={PAGE_SIZE}
                    onPageChange={handlePagePendingChange}
                  />
                </div>
              ) : null
          }
        </div>
      </div>

      {showSigningSection && hasAccess(Permissions.DocumentsSign) && (
        <div className='fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200 p-4 z-10 '>
          <div className='flex size-full justify-center md:justify-end items-center'>
            <div className='w-full md:w-fit'>
              <Link to={ROUTES.SIGNATURE_PREVIEW}>
                <Button
                  size={ButtonSize.medium}
                  text={`Ver documentos (${selectedDocuments.length})`}
                  hasBg={true} />
              </Link>
            </div>
          </div>
        </div>
      )}

    </div>
  );

};
