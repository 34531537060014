import { Outlet, useLocation } from 'react-router-dom'
import { usePermissions } from '../../auth/hooks/usePermission'
import { Permissions } from '../../auth/types/Permissions'
import { ROUTES } from '../../router/routes'
import { SettingItem } from '../components/SettingItem'
import { AllSettings } from '../types/allSettings'
import { globals } from '../../shared/constants/global'


export const SettingsLayout = () => {
    const { hasAccess } = usePermissions()
    const { pathname } = useLocation()
    const isSelected = [ROUTES.ORGANIZATIONS_SETTINGS, ROUTES.CERTIFICATIONS_SETTINGS, ROUTES.NOTIFICATIONS_SETTINGS, ROUTES.YOURDATA_SETTINGS]
        .some(route => pathname.startsWith(route));

    return (
        <div className={`grid grid-cols-1 sm:grid-cols-[240px_1fr]   bg-NeutralNeutral6`}>
            <div className={`w-full sm:w-[30%] sm:min-w-64 border-b sm:border-b-0 sm:border-r border-NeutralNeutral5 sm:m-4 rounded-md bg-white sm:h-fit ${isSelected ? 'hidden sm:block' : 'block '}`}>
                <div className='flex flex-col'>
                    {AllSettings.filter((setting) => {
                        if (setting.route === ROUTES.ORGANIZATIONS_SETTINGS) {
                            return hasAccess(Permissions.SettingsOrganizationRead)
                        }
                        return true
                    }).map((setting, index) => (
                        <div key={index} className='flex flex-col w-full h-14 justify-center items-center'>
                            <SettingItem setting={setting} />
                            <hr className='h-[1px] w-full border-0 bg-NeutralNeutral5' />
                        </div>
                    ))}
                </div>
            </div>
            {
                isSelected ?
                    <div className="flex-1 overflow-auto my-4 sm:mx-24 mx-4 m-auto bg-white rounded-md">
                        {isSelected ? (
                            <Outlet />
                        ) : (
                            null
                        )}
                    </div>
                    :
                    <div className="hidden sm:block">
                        <div className="text-center">Selecciona un ajuste</div>
                    </div>
            }

        </div>
    )
}