import { Coordinates, Size } from '../types'

export interface PdfScalingProps {
    pdfFirstWidth: number
    pdfFirstHeight: number
    pdfFinalWidth: number
    pdfFinalHeight: number
    size: Size
    coordinates: Coordinates
}

export const usePdfScaling = ({
    pdfFirstWidth,
    pdfFirstHeight,
    pdfFinalWidth,
    pdfFinalHeight,
    size,
    coordinates
}: PdfScalingProps) => {
    // Calcular los factores de escala
    const scaleX = pdfFinalWidth / pdfFirstWidth;
    const scaleY = pdfFinalHeight / pdfFirstHeight;
    // Aplicar la escala a las coordenadas y dimensiones 
    const scaledSizeX = size.width * scaleX;
    const scaledSizeY = size.height * scaleY;
    const scaledCoordinatesX = coordinates.x * scaleX;
    const scaledCoordinatesY = coordinates.y * scaleY;

    const scaledSize: Size = {
        width: scaledSizeX,
        height: scaledSizeY
    };

    const scaledCoordinates: Coordinates = {
        x: scaledCoordinatesX,
        y: scaledCoordinatesY
    };

    return {
        scaledSize,
        scaledCoordinates
    }
}
