import { useQuery } from '@tanstack/react-query';
import RegisterIcon from "../../assets/icons/registerValid.svg";
import api from '../../shared/api/Axios';
import { SecurityEndpoints } from '../../shared/api/services/ConfigApi';
import { useInfoLocalStorage } from '../hooks/useInfoLocalStorage';
import { useLocation } from 'react-router-dom';


export const LoginErrorPage = () => {
    const { state } = useLocation();
    const { externalId, name, mail, surname1, surname2, phone } = state as { externalId: string, name: string, mail: string, surname1: string, surname2: string, phone: string };
    // const { getExternalUserIdLocalStorage } = useInfoLocalStorage()
    // const externalId = getExternalUserIdLocalStorage()
    const { data } = useQuery({
        queryKey: ['registerNewUser'],
        queryFn: async () => {
            const newUser = {
                externalId: externalId,
                name: name,
                mail: mail,
                surname1: surname1,
                surname2: surname2,
                phone: phone,
            }
            return await api.post(SecurityEndpoints.RegisterNewUser(), newUser)
        },
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    })

    return (
        <div className="h-screen w-screen ">
            <div className="flex flex-col items-center w-full  h-full px-8 py-6 gap-5 md:flex md:flex-row md:justify-evenly md:items-center ">
                <div className="border p-6 bodym flex flex-row md:items-center">
                    <div className="basis-1/12">
                        <img src={RegisterIcon} alt="check" />
                    </div>
                    <div className="basis-11/12">
                        <p className="h6 text-NeutralNeutral1 mb-4">Estamos procesando tu solicitud.</p>
                        <p className="text-NeutralNeutral2">El alta de usuarios en EducaSign requiere de una validación manual por parte de nuestro equipo. Una vez validado tu perfil, recibirás una notificación por correo electrónico.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
